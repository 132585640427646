<template>
    <b-overlay :show="isLoading" variant="white">
        <b-card class="white-bg">
            <b-form>
                <b-row class="mb-4">
                    <b-col cols="12" md="12">
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Fecha" label-cols-md="2">
                                    <b-form-datepicker boundary="window" placeholder="Fecha" v-bind="datePickerLabels"
                                                       locale="es" hide-header v-model="filters.date"></b-form-datepicker>
                                </b-form-group>
                                <b-form-group label="Hotel" label-cols-md="2" class="mt-3">
                                    <v-select :options="hotels" :filterable="true" v-model="filters.hotel" placeholder="Hotel"
                                              :clearable="true" @input="setFilterHotel">
                                        <template #no-options>Sin resultados.</template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Cliente" label-cols-md="2">
                                    <v-select :options="businessEntities" :filterable="true" v-model="filters.businessEntity"
                                              placeholder="Cliente" label="name" :clearable="true" @input="setFilterBusinessEntity">
                                        <template #no-options>Sin resultados.</template>
                                    </v-select>
                                </b-form-group>
                                <b-row>
                                    <b-col lg="6" md="12">
                                        <b-form-group label="Horario de vuelo" label-cols-md="5">
                                            <v-select :options="flightSchedules" :filterable="true" v-model="filters.flightSchedule"
                                                      placeholder="Horario de vuelo" :clearable="true" @input="setFilterFlightSchedule">
                                                <template #no-options>Sin resultados.</template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="6" md="12">
                                        <b-form-group label="Número de vuelo" label-cols-md="5">
                                            <v-select :options="flightNumbers" :filterable="true" v-model="filters.flightNumber"
                                                      placeholder="Número de vuelo" :clearable="true" @input="setFilterFlightNumber">
                                                <template #no-options>Sin resultados.</template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-form-group label="Servicio" label-cols-md="1">
                            <v-select :options="services" :filterable="true" v-model="filters.service"
                                      placeholder="Servicio" label="name" :clearable="true" @input="setFilterService">
                                <template #no-options>Sin resultados.</template>
                            </v-select>
                        </b-form-group>
                    </b-col>
<!--                    <b-col cols="12" md="2">-->
<!--                    </b-col>-->
                </b-row>
            </b-form>
            <hr>
            <b-row class="ml-1 mr-1">
                <b-col md="4" class="p-0 pr-4">
                    <b-row class="m-0">
                        <b-col md="5" class="p-0">
                              <h5 class="m-0 ml-2 mt-1 mb-1">Vehículos </h5>
                        </b-col>
<!--                        <b-col md="5" class="p-0">-->
<!--                            <label id="service-orders-unassigned" style="font-size: 14px"> Ordenes de servicio disponibles: <span class="text-primary">{{ unassignedCount }}</span></label>-->
<!--                            <b-popover target="service-orders-unassigned" placement="right" triggers="hover" title="Folios disponibles" v-if="serviceOrdersUnassigned">-->
<!--                                <template v-for="(serviceOrderUnassigned, index) in serviceOrdersUnassigned">-->
<!--                                    <label :key="'service-order-unassigned-' + index" style="font-size: 14px">Folio: <span class="text-primary">{{ serviceOrderUnassigned.folio }}</span></label>-->
<!--                                </template>-->
<!--                            </b-popover>-->
<!--                        </b-col>-->
                        <b-col md="7" class="p-0 text-right">
                            <h5 class="m-0 mr-2 mt-1 font-weight-bold"><span class="text-primary">{{ formatDate(filters.date) }}</span></h5>
                        </b-col>
                    </b-row>
                    <b-table-simple responsive bordered striped class="table-customer-height">
                        <b-tbody>
                            <template v-for="(availableVehicle, index) in availableVehicles">
                                <b-tr :key="'available-vehicle-' + index" :variant="availableVehicle.isActive ? 'primary' : ''" @click="selectedVehicle(availableVehicle)">
                                    <b-td colspan="2">
                                        <b-row no-gutters>
                                            <b-col :md="availableVehicle.type === 'EXTERNAL_SERVICE' ? 12 : 9" class="m-0 font-weight-bold">
                                                {{ availableVehicle.brand + ' ' + availableVehicle.model + ' [' + availableVehicle.licensePlate + ']' }}
                                                <span class="text-primary" v-if="availableVehicle.type === 'RENT'">(R)</span>
                                                <span class="text-primary" v-if="availableVehicle.type === 'EXTERNAL_SERVICE'">(EXS)</span>
                                            </b-col>
                                            <b-col md="3" v-if="availableVehicle.type !== 'EXTERNAL_SERVICE'">
                                                <h5 class="mr-2 mt-1 mb-0 font-weight-bold text-right">{{ availableVehicle.passengerCapacity }}</h5>
                                            </b-col>
                                        </b-row>
                                        <template v-if="availableVehicle.serviceOrderId">
                                            <label class="mt-0 mb-0">{{ availableVehicle.serviceName }}</label>
                                        </template>
                                        <label class="text-primary mb-0" v-if="availableVehicle.serviceOrders.length === 0">Sin asignación</label>
                                    </b-td>
                                </b-tr>
                                <b-tr v-for="(serviceOrder) in availableVehicle.serviceOrders" :key="'available-vehicle-service-order-' + serviceOrder.id"
                                      :variant="serviceOrder.isActive ? 'info' : ''" :class="{'text-danger': serviceOrder.status === 'CANCELLED'}"
                                      :style="[serviceOrder.status === 'CANCELLED' ? {'text-decoration': 'line-through'} : '']"
                                      @click="selectedServiceOrder(serviceOrder, availableVehicle)">
                                    <td style="width: 5%; border-left: none;">
                                        <b-row no-gutters>
                                            <b-col class="m-0">
                                                <template v-if="serviceOrder.serviceDurationType === 'SPECIFIC'">
                                                    <b-button variant="primary" class="m-0" @click.stop="openScheduleModal(serviceOrder, availableVehicle)"
                                                              v-b-tooltip.hover title="Asignar horario" :disabled="serviceOrder.status === 'CANCELLED'">
                                                        <font-awesome-icon icon="clock"></font-awesome-icon>
                                                    </b-button>
                                                </template>
                                            </b-col>
                                        </b-row>
                                    </td>
                                    <b-td>
                                    <!-- {{ serviceOrder }}-->
                                        <b-row no-gutters>
                                            <b-col md="5" class="m-0">{{ serviceOrder.serviceName }}<span v-if="serviceOrder.statusLock == 1"> - <font-awesome-icon icon="lock"></font-awesome-icon></span></b-col>
                                            <b-col md="4" class="m-0">
                                                <template v-if="serviceOrder.serviceDurationType === 'SPECIFIC'">
                                                    <label class="mt-0 mb-0 text-primary" v-if="serviceOrder.startTime">{{ serviceOrder.startTime.split(':').slice(0,2).join(':') }} - {{ serviceOrder.endTime.split(':').slice(0,2).join(':') }}</label>
                                                    <label class="mt-0 mb-0 text-danger" v-if="!serviceOrder.startTime || !serviceOrder.endTime">Horario no asignado</label>
                                                </template>
                                                <label class="mt-0 mb-0 text-primary" v-if="serviceOrder.serviceDurationType === 'ALL'">Asignado todo el dia</label>
                                                <template v-if="serviceOrder.operatorName">
                                                    <br>
                                                    {{ serviceOrder.operatorName }}
                                                    <span v-if="operatorSchedulesOverlap(serviceOrder)" class="text-warning" v-b-tooltip.hover
                                                          title="Este operador ya está asignado a otros servicios en este horario">
                                                        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
                                                    </span>
                                                </template>
                                            </b-col>
                                            <b-col md="3">
                                                <h5 class="mr-2 mt-1 mb-0 font-weight-bold text-right"><span :class="{'text-primary': (serviceOrder.adultsQty + serviceOrder.childrenQty) > 0, 'text-success': availableVehicle.passengerCapacity === (serviceOrder.adultsQty + serviceOrder.childrenQty)}">{{ serviceOrder.adultsQty + serviceOrder.childrenQty }} / {{ availableVehicle.passengerCapacity }}</span></h5>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                    </b-table-simple>
                    <b-button variant="primary" class="mr-2" v-b-modal="'vehicle-modal'"><font-awesome-icon icon="car"></font-awesome-icon> Agregar vehiculo</b-button>
                    <b-button variant="primary" class="mr-2" v-b-modal="'external-service-modal'"> Sevicio Externo</b-button>
<!--                    <b-button variant="danger" class="mr-2" :disabled="!activeVehicle" @click="disabledVehicle"> Desactivar vehiculo</b-button>-->
                </b-col>
                <b-col md="8" style="border-radius: 2.5px; border: 1px solid rgba(38, 38, 38, 0.125);" class="p-3">
                    <b-row>
                        <b-col md="5">
                            <b-row class="m-0">
                                <b-col md="5" class="p-0">
                                    <h5 class="m-0 ml-2 mt-1">Por asignar</h5>
                                </b-col>
                                <b-col md="7" class="p-0 text-right">
                                    <h5 class="m-0 mr-2 mt-1 font-weight-bold"><span class="text-primary">{{ adultsToAssign }}</span> Adultos | <span class="text-primary">{{ childrenToAssign }}</span> Niños = <span class="text-primary">{{ adultsToAssign + childrenToAssign }} </span>pax</h5>
                                </b-col>
                            </b-row>
                            <template v-if="selectedAdultsToAssign > 0 || selectedChildrenToAssign > 0">
                                <hr class="mt-1 mb-1">
                                <b-row class="m-0">
                                    <b-col md="5" class="p-0">
                                        <h5 class="m-0 ml-2 mt-1">Seleccionados</h5>
                                    </b-col>
                                    <b-col md="7" class="p-0 text-right">
                                        <h5 class="m-0 mr-2 mt-1 font-weight-bold"><span class="text-primary">{{ selectedAdultsToAssign }}</span> Adultos | <span class="text-primary">{{ selectedChildrenToAssign }}</span> Niños = <span class="text-primary">{{ selectedAdultsToAssign + selectedChildrenToAssign }} </span>pax</h5>
                                    </b-col>
                                </b-row>
                            </template>
                            <b-table-simple responsive bordered striped class="mt-2 table-customer-height">
                                <b-tbody>
                                    <b-tr v-for="data in dataToAssign" :key="'data-to-assign-' + data.businessOrderItemId"
                                          :variant="data.isActive ? 'primary' : ''" @click="data.isActive = !data.isActive">
                                        <b-td>
                                            <b-row class="m-0">
                                                <b-col md="8" class="pl-2 pr-2">{{ data.businessEntityName }}</b-col>
                                                <b-col md="4" class="text-right pl-2 pr-2"><router-link :to="{ name: 'BusinessOrderShow', params: { id: data.businessOrderId }}" target="_blank">{{ data.folio }}</router-link></b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="8" class="pl-2 pr-2">{{ data.entityType === 'TRANSFER' ? formatDate(data.startDate) : formatDate(data.startDate) + ' / ' + formatDate(data.endDate) }}</b-col>
                                                <b-col md="4" class="text-right pl-2 pr-2"><template v-if="data.entityType === 'TRANSFER' && (data.flightSchedule !== '' || data.flightNumber !== '')">{{ data.flightSchedule }} / {{ data.flightNumber }}</template></b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="6" class="pl-2 pr-2"><template v-if="data.hotel1 !== '' || data.hotel2 !== ''">{{ data.entityType === 'TRANSFER' ? data.hotel1 : data.hotel1 + ' / ' + data.hotel2 }}</template></b-col>
                                                <b-col md="6" class="text-right pl-2 pr-2 text-danger font-weight-bold"><template v-if="data.cancelAdults || data.cancelChildren">Cancelados: {{ data.cancelAdults + data.cancelChildren }}</template></b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="7" class="pl-2 pr-2 font-weight-bold">{{ data.serviceName }} ({{ data.type === 'COLLECTIVE' ? 'Colectivo' : 'Exclusivo' }})</b-col>
                                                <b-col md="5" class="text-right pl-2 pr-2 font-weight-bold">Adultos {{ data.availableAdults }}/Niños {{ data.availableChildren }}</b-col>
                                            </b-row>
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="dataToAssign.length === 0">
                                        <b-td class="text-center">
                                            Sin registros
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <b-button variant="danger" class="mr-2" :disabled="selectedDataToAssign == ''" @click="getPassengersToSelect()">Cancelación de servicios</b-button>
                            <b-button v-if="serviceOrder.status !== 'CANCELLED'" variant="default" class="mr-2" :disabled="!activeVehicle || (selectedAdultsToAssign === 0 && selectedChildrenToAssign === 0 || serviceOrder.statusLock == 1)" v-b-modal="'split-business-modal'">Editar pasajeros</b-button>
                        </b-col>
                        <b-col md="1" class="text-center mt-auto mb-auto">
                            <b-button v-if="serviceOrder.status !== 'CANCELLED'" variant="default" style="width: 100%" class="mb-3" :disabled="!activeVehicle || (adultsToAssign === 0 && childrenToAssign === 0 || serviceOrder.statusLock == 1)"
                                      @click="addServiceOrderItem(true)"><font-awesome-icon icon="angle-double-right"></font-awesome-icon></b-button>
                            <br>
                            <b-button v-if="serviceOrder.status !== 'CANCELLED'" variant="default" style="width: 100%" class="mb-3" :disabled="!activeVehicle || (selectedAdultsToAssign === 0 && selectedChildrenToAssign === 0 || serviceOrder.statusLock == 1)"
                                      @click="addServiceOrderItem(false)"><font-awesome-icon icon="angle-right"></font-awesome-icon></b-button>
                            <br>
                            <b-button v-if="serviceOrder.status !== 'CANCELLED'" variant="default" style="width: 100%" class="mb-3" :disabled="selectedServiceOrderItems.length === 0 || serviceOrder.statusLock == 1" @click="removeServiceOrderItem(false)"><font-awesome-icon icon="angle-left"></font-awesome-icon></b-button>
                            <br>
                            <b-button v-if="serviceOrder.status !== 'CANCELLED'" variant="default" style="width: 100%" class="mb-3" :disabled="serviceOrder.serviceOrderItems.length === 0 || serviceOrder.statusLock == 1" @click="removeServiceOrderItem(true)"><font-awesome-icon icon="angle-double-left"></font-awesome-icon></b-button>
                        </b-col>
                        <b-col md="6">
                            <b-row class="m-0" v-if="activeVehicle">
                                <b-col md="9" class="p-0"><h5 class="m-0 mr-2 mt-1 font-weight-bold text-primary">{{ serviceOrder.vehicleBrand + ' ' + serviceOrder.vehicleModel + ' [' + serviceOrder.vehicleLicensePlate + ']'}}</h5></b-col>
                                <b-col md="3" class="p-0 text-right">
                                    <h5 class="m-0 mr-2 mt-1 font-weight-bold"><span :class="{'text-primary': serviceOrder.availabilityPassengers > 0, 'text-success': serviceOrder.availabilityPassengers === 0}">
                                        {{ serviceOrder.adultsQty + serviceOrder.childrenQty }} / {{ serviceOrder.passengerCapacity }}</span>
                                    </h5>
                                </b-col>
                            </b-row>
                            <b-row class="m-0" v-if="!activeVehicle">
                                <b-col md="12" class="p-0">
                                    <h5 class="m-0 ml-2 mt-1">Vehiculo no seleccionado</h5>
                                </b-col>
                            </b-row>
                            <template v-if="serviceOrder.serviceId > 0">
                                <hr class="mt-1 mb-1">
                                <b-row class="m-0">
                                    <b-col md="8" class="p-0">
                                        <h5 class="m-0 ml-2 mt-1">{{ serviceOrder.serviceName }} ({{ serviceOrder.serviceType === 'COLLECTIVE' ? 'Colectivo' : 'Exclusivo' }}) -
                                            <b-badge variant="warning" v-if="serviceOrder.status === 'DRAFT'">Borrador</b-badge>
                                            <b-badge variant="info" v-if="serviceOrder.status === 'OPEN'">Abierta</b-badge>
                                            <b-badge variant="primary" v-if="serviceOrder.status === 'FINISHED'">Terminada</b-badge>
                                            <b-badge variant="danger" v-if="serviceOrder.status === 'CANCELLED'">Cancelada</b-badge>
                                        </h5>
                                    </b-col>
                                    <b-col md="4" class="p-0 text-right" v-if="serviceOrder.folio">
                                        <h5 class="m-0 ml-2 mt-1"><router-link :to="{ name: 'ServiceOrderShow', params: { id: serviceOrder.id }}" target="_blank">{{ serviceOrder.folio }}</router-link></h5>
                                    </b-col>
                                </b-row>
                            </template>
                            <b-table-simple responsive bordered striped class="mt-2 mb-0 table-customer-height-v2">
                                <b-tbody>
                                    <b-tr v-if="serviceOrder.serviceOrderItems.length === 0">
                                        <td class="text-center">Sin registros</td>
                                    </b-tr>
                                    <b-tr v-for="(serviceOrderItem, index) in serviceOrder.serviceOrderItems" :key="'service-order-item' + index"
                                          :variant="serviceOrderItem.isActive ? 'primary' : ''" @click="serviceOrderItem.isActive = !serviceOrderItem.isActive">
                                        <b-td>
                                            <b-row class="m-0">
                                                <b-col md="8" class="pl-2 pr-2">{{ serviceOrderItem.businessOrderItem.businessEntityName }}</b-col>
                                                <b-col md="4" class="text-right pl-2 pr-2"><router-link :to="{ name: 'BusinessOrderShow', params: { id: serviceOrderItem.businessOrderItem.businessOrderId }}" target="_blank">{{ serviceOrderItem.businessOrderItem.folio }}</router-link></b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="8" class="pl-2 pr-2">
                                                    <template v-if="serviceOrderItem.businessOrderItem.entityType === 'TRANSFER' ||
                                                        serviceOrderItem.businessOrderItem.startDate === serviceOrderItem.businessOrderItem.endDate">
                                                        {{ formatDate(serviceOrderItem.businessOrderItem.startDate) }}
                                                    </template>
                                                    <template v-else>
                                                        {{ formatDate(serviceOrderItem.businessOrderItem.startDate) }} /
                                                        {{ formatDate(serviceOrderItem.businessOrderItem.endDate) }}
                                                    </template>
                                                </b-col>
                                                <b-col md="4" class="text-right pl-2 pr-2">
                                                    <template v-if="serviceOrderItem.businessOrderItem.entityType === 'TRANSFER' &&
                                                        (serviceOrderItem.businessOrderItem.flightSchedule !== '' || serviceOrderItem.businessOrderItem.flightNumber !== '')">
                                                        {{ serviceOrderItem.businessOrderItem.flightSchedule }} / {{ serviceOrderItem.businessOrderItem.flightNumber }}
                                                    </template>
                                                    <template v-else-if="serviceOrderItem.businessOrderItem.entityType === 'TOUR'">
                                                        <template v-if="serviceOrder.startTime !== '' && serviceOrder.endTime !== ''">
                                                            {{ serviceOrder.startTime }} - {{ serviceOrder.endTime }}
                                                        </template>
                                                        <template v-else>
                                                            {{ serviceOrderItem.businessOrderItem.startTime }} - {{ serviceOrderItem.businessOrderItem.endTime }}*
                                                        </template>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="6" class="pl-2 pr-2"><template v-if="serviceOrderItem.businessOrderItem.hotel1 !== '' || serviceOrderItem.businessOrderItem.hotel2 !== ''">{{ serviceOrderItem.businessOrderItem.entityType === 'TRANSFER' ? serviceOrderItem.businessOrderItem.hotel1 : serviceOrderItem.businessOrderItem.hotel1 + ' / ' + serviceOrderItem.businessOrderItem.hotel2 }}</template></b-col>
                                                <b-col md="6" class="text-right pl-2 pr-2 text-danger font-weight-bold"><template v-if="serviceOrderItem.businessOrderItem.cancelAdults || serviceOrderItem.businessOrderItem.cancelChildren">Cancelados: {{ serviceOrderItem.businessOrderItem.cancelAdults + serviceOrderItem.businessOrderItem.cancelChildren }}</template></b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="7" class="pl-2 pr-2 font-weight-bold">{{ serviceOrderItem.businessOrderItem.serviceName }}</b-col>
                                                <b-col md="5" class="text-right pl-2 pr-2 font-weight-bold">Adultos {{ serviceOrderItem.adults }}/Niños {{ serviceOrderItem.children }}</b-col>
                                            </b-row>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <b-form-group class="mt-0">
                                <b-form-group>
                                    <label>
                                        Operador
                                        <span v-if="serviceOrder.operatorId && operatorSchedulesOverlap(serviceOrder)" class="text-warning" v-b-tooltip.hover
                                              title="Este operador ya está asignado a otros servicios en este horario">
                                            <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
                                        </span>
                                    </label>
                                    <v-select :options="users" @search:focus="getUsers" @search="getUsers" v-model="serviceOrder.operatorId"
                                              placeholder="Operador" label="name" :clearable="false" :reduce="user => user.id">
                                        <template #no-options>Sin resultados.</template>
                                    </v-select>
                                </b-form-group>
                            </b-form-group>
                            <b-row class="mt-3">
                                <b-col md="12" class="mb-3">
                                    <b-button v-if="serviceOrder.status !== 'CANCELLED'" variant="primary" class="mr-2" :disabled="!activeVehicle || !serviceOrder.serviceId || serviceOrder.statusLock == 1" @click="saveServiceOrder"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                                    <b-button variant="primary" class="mr-2" :disabled="!activeVehicle || !serviceOrder.id"  @click="servicePage"><font-awesome-icon icon="print"></font-awesome-icon> Imprimir</b-button>
                                    <template v-if="serviceOrder.status !== 'FINISHED' && serviceOrder.status !== 'CANCELLED'">
                                        <b-button variant="info" class="mr-2" :disabled="!activeVehicle || !serviceOrder.id" @click="statusServiceOrder" ><font-awesome-icon icon="check"></font-awesome-icon>
                                            {{ serviceOrder.status === 'DRAFT' ? 'Procesar' : 'Terminar' }}
<!--                                            <span v-if="serviceOrder.status === 'DRAFT'"> Procesar</span>-->
<!--                                            <span v-if="serviceOrder.status === 'OPEN'"> Terminar</span>-->
                                        </b-button>
                                    </template>
                                    <b-button variant="danger" class="mr-2" @click="removeServiceOrder" :disabled="!serviceOrder.serviceId" v-if="serviceOrder.status === 'DRAFT'"> Eliminar</b-button>
                                    <b-button variant="danger" class="mr-2" @click="cancelServiceOrder" :disabled="serviceOrder.statusLock == 1" v-if="serviceOrder.status === 'OPEN'"> Cancelar</b-button>
                                </b-col>
                                <b-col md="12" class="mb-3">
                                    <template v-if="serviceOrder.status === 'OPEN'">
                                        <b-button variant="secondary" class="mr-2" @click="statusLockChange"><font-awesome-icon icon="lock"></font-awesome-icon>
                                            <span v-if="serviceOrder.statusLock != 1"> Bloquear</span>
                                            <span v-if="serviceOrder.statusLock == 1"> Desbloquear</span>
                                        </b-button>
                                    </template>
                                    <b-button  v-if="serviceOrder.status !== 'CANCELLED'" variant="default" class="mr-2" :disabled="selectedServiceOrderItems.length === 0 || serviceOrder.statusLock == 1" v-b-modal="'split-service-modal'">Editar pasajeros</b-button>
                                    <b-button  v-if="serviceOrder.status !== 'CANCELLED'" variant="primary" class="mr-2" :disabled="serviceOrder.expenseList == '' || serviceOrder.statusLock == 1" v-b-modal="'expense-modal'"><font-awesome-icon icon="dollar-sign"></font-awesome-icon> Gastos</b-button>
                                </b-col>
                                <b-col md="12">
                                    <template v-if="serviceOrder.expenseList != ''">
                                        <hr class="mt-1 mb-3">
                                        <h5 id="service-orders-unassigned"> Gastos totales: <span class="text-primary">${{ formatCurrency(expenseTotal) }}</span></h5>
                                        <b-row>
                                            <b-col>
                                                <b-table-simple responsive bordered striped class="table-customer-height-v2">
                                                    <b-thead>
                                                        <b-tr>
                                                            <b-th>Concepto</b-th>
                                                            <b-th style="text-align: right">Cantidad</b-th>
                                                            <b-th style="text-align: right">Costo Unitario</b-th>
                                                            <b-th style="text-align: right">Subtotal</b-th>
                                                        </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                        <b-tr v-for="(expense, index) in serviceOrder.expenseList" :key="'expense-' + index" :class="{'text-danger': expense.isCancelled }" :style="[  expense.isCancelled ? {'text-decoration': 'line-through'} : '']">
                                                            <template v-if="expense.name && expense.unitPrice">
                                                                <b-td><span class="text-primary" v-if="expense.isExtra"><strong>Extra - </strong></span>{{ expense.name }}</b-td>
                                                                <b-td style="text-align: right">{{ expense.qty }}</b-td>
                                                                <b-td style="text-align: right">${{ expense.unitPrice }}</b-td>
                                                                <b-td style="text-align: right">${{ expense.total }}</b-td>
                                                            </template>
                                                        </b-tr>
                                                    </b-tbody>
                                                    <tfoot>
                                                        <b-tr>
                                                            <b-th colspan="3" style="text-align: right">Total</b-th>
                                                            <b-th style="text-align: right">${{ formatCurrency(expenseTotal) }}</b-th>
                                                        </b-tr>
                                                    </tfoot>
                                                </b-table-simple>
                                                <template v-if="serviceOrder.status === 'OPEN'">
                                                    <b-button variant="primary" @click="expensesPayment"> Pagar gastos</b-button>
                                                </template>
                                            </b-col>
                                        </b-row>
                                    </template>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <!-- schedule modal -->
            <template>
                <b-modal id="schedule-modal" centered size="lg" title="Registro de Horario" hide-header-close no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <b-form @submit.stop.prevent="passes(saveSchedule)" id="schedule-form">
                            <b-row class="m-0">
                                <b-col md="8" class="p-0">
                                    <h5 class="m-0 ml-2 mt-1">
                                        {{ scheduleServiceOrder.serviceName }} ({{ scheduleServiceOrder.serviceType === 'COLLECTIVE' ? 'Colectivo' : 'Exclusivo' }}) -
                                        <b-badge variant="warning" v-if="scheduleServiceOrder.status === 'DRAFT'">Borrador</b-badge>
                                        <b-badge variant="info" v-if="scheduleServiceOrder.status === 'OPEN'">Abierta</b-badge>
                                        <b-badge variant="primary" v-if="scheduleServiceOrder.status === 'FINISHED'">Terminada</b-badge>
                                    </h5>
                                </b-col>
                                <b-col md="4" class="p-0 text-right" v-if="scheduleServiceOrder.folio">
                                    <h5 class="m-0 ml-2 mt-1">
                                        <router-link :to="{ name: 'ServiceOrderShow', params: { id: scheduleServiceOrder.id }}" target="_blank">{{ scheduleServiceOrder.folio }}</router-link>
                                    </h5>
                                </b-col>
                            </b-row>
                            <b-table-simple responsive bordered striped class="mt-2 table-customer-height-v2">
                                <b-tbody>
                                    <b-tr v-for="(serviceOrderItem, index) in scheduleServiceOrder.serviceOrderItems" :key="'service-order-item' + index"
                                          :variant="serviceOrderItem.isActive ? 'primary' : ''">
                                        <b-td>
                                            <b-row class="m-0">
                                                <b-col md="8" class="pl-2 pr-2">{{ serviceOrderItem.businessOrderItem.businessEntityName }}</b-col>
                                                <b-col md="4" class="text-right pl-2 pr-2"><router-link :to="{ name: 'BusinessOrderShow', params: { id: serviceOrderItem.businessOrderItem.businessOrderId }}" target="_blank">{{ serviceOrderItem.businessOrderItem.folio }}</router-link></b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="8" class="pl-2 pr-2">
                                                    <template v-if="serviceOrderItem.businessOrderItem.startDate === serviceOrderItem.businessOrderItem.endDate">
                                                        {{ formatDate(serviceOrderItem.businessOrderItem.startDate) }}
                                                    </template>
                                                    <template v-else>
                                                        {{ formatDate(serviceOrderItem.businessOrderItem.startDate) }} /
                                                        {{ formatDate(serviceOrderItem.businessOrderItem.endDate) }}
                                                    </template>
                                                </b-col>
                                                <b-col md="4" class="text-right pl-2 pr-2"><template v-if="serviceOrderItem.businessOrderItem.entityType === 'TRANSFER' && (serviceOrderItem.businessOrderItem.flightSchedule !== '' || serviceOrderItem.businessOrderItem.flightNumber !== '')">{{ serviceOrderItem.businessOrderItem.flightSchedule }} / {{ serviceOrderItem.businessOrderItem.flightNumber }}</template></b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="12" class="pl-2 pr-2"><template v-if="serviceOrderItem.businessOrderItem.hotel1 !== '' || serviceOrderItem.businessOrderItem.hotel2 !== ''">{{ serviceOrderItem.businessOrderItem.entityType === 'TRANSFER' ? serviceOrderItem.businessOrderItem.hotel1 : serviceOrderItem.businessOrderItem.hotel1 + ' / ' + serviceOrderItem.businessOrderItem.hotel2 }}</template></b-col>
                                            </b-row>
                                            <b-row class="m-0">
                                                <b-col md="7" class="pl-2 pr-2 font-weight-bold">{{ serviceOrderItem.businessOrderItem.serviceName }}</b-col>
                                                <b-col md="5" class="text-right pl-2 pr-2 font-weight-bold">Adultos {{ serviceOrderItem.adults }}/Niños {{ serviceOrderItem.children }}</b-col>
                                            </b-row>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <b-row class="mb-2">
                                <b-col md="12">
                                    <p class="m-0 mr-2 mt-1">
                                        Horario:
                                        <span class="text-primary font-weight-bold">
                                            {{ scheduleServiceOrder.serviceStartTime.split(':').slice(0,2).join(':') }} -
                                            {{ scheduleServiceOrder.serviceEndTime.split(':').slice(0,2).join(':') }}
                                        </span>
                                    </p>
                                </b-col>
                                <b-col md="6" class="text-left" >
                                    <ValidationProvider :rules="{required: true, regex: /\d\d:\d\d/ }" v-slot="{ errors }">
                                        <b-form-group label="Hora de inicio" :invalid-feedback="scheduleServiceOrder.startTime.trim() === '' ? 'Ingresa la hora de inicio' :
                                                      'Ingresa una hora de inicio válida'">
                                            <b-form-input autocomplete="false" placeholder="Hora de inicio" v-model="scheduleServiceOrder.startTime"
                                                          :state="errors[0] ? false : null" data-inputmask="'alias': 'datetime', 'inputFormat': 'HH:MM'"
                                                          class="inputmask"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6" class="text-left" >
                                    <ValidationProvider :rules="{required: true, regex: /\d\d:\d\d/ }" v-slot="{ errors }">
                                        <b-form-group label="Hora de término" :invalid-feedback="scheduleServiceOrder.endTime.trim() === '' ? 'Ingresa la hora de término' :
                                                      'Ingresa una hora de término válida'">
                                            <b-form-input autocomplete="false" placeholder="Hora de término" v-model="scheduleServiceOrder.endTime"
                                                          :state="errors[0] ? false : null" data-inputmask="'alias': 'datetime', 'inputFormat': 'HH:MM'"
                                                          class="inputmask"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button variant="outline-secondary" @click="$bvModal.hide('schedule-modal')" class="mr-2"> Cancelar</b-button>
                            <b-button type="submit" form="schedule-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                        </div>
                    </template>
                </b-modal>
            </template>

            <!-- vehicle modal -->
            <template>
                <b-modal id="vehicle-modal" centered size="xl" title="Registro de Vehiculo" hide-header-close no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(saveVehicle)" id="vehicle-form">
                            <b-row class="mb-2">
                                <b-col cols="12" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Tipo de vehiculo">
                                            <b-form-radio-group v-model="vehicle.type" :options="vehicleTypes" :state="errors[0] ? false : null">
                                                <b-form-invalid-feedback :state="errors[0] ? false : null">Ingresa el tipo de vehiculo</b-form-invalid-feedback>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4" class="text-left">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Marca" invalid-feedback="Ingresa la marca">
                                            <b-form-input v-model="vehicle.brand" autocomplete="false" placeholder="Marca"
                                                          :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4" class="text-left">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Modelo" invalid-feedback="Ingresa el modelo">
                                            <b-form-input v-model="vehicle.model" autocomplete="false" placeholder="Modelo"
                                                          :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4" class="text-left">
                                    <ValidationProvider rules="integer" v-slot="{ errors }">
                                        <b-form-group label="Año" invalid-feedback="Ingresa sólo números">
                                            <b-form-input v-model="vehicle.year" autocomplete="false" placeholder="Año"
                                                          :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4" class="text-left">
                                    <b-form-group label="Placa">
                                        <b-form-input v-model="vehicle.licensePlate" autocomplete="false" placeholder="Placa"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" class="text-left">
                                    <ValidationProvider rules="required|integer" v-slot="{ errors }">
                                        <b-form-group label="Capacidad de pasajeros" :invalid-feedback="vehicle.passengerCapacity.trim() === '' ?
                                                              'Ingresa la capacidad de pasajeros' : 'Ingresa sólo números'">
                                            <b-form-input v-model="vehicle.passengerCapacity" autocomplete="false" placeholder="Capacidad de pasajeros"
                                                          :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4" v-if="vehicle.type === 'RENT'">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Tipo de pago">
                                            <b-form-radio-group v-model="vehicle.paymentType" :options="paymentTypes" :state="errors[0] ? false : null">
                                                <b-form-invalid-feedback :state="errors[0] ? false : null">Ingresa el tipo de pago</b-form-invalid-feedback>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4" class="text-left" v-if="vehicle.type === 'RENT'">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Proveedor" invalid-feedback="Selecciona el proveedor" :state="errors[0] ? false : null">
                                            <v-select :options="businessEntitySuppliers" @search:focus="getBusinessEntity" @search="getBusinessEntity" v-model="vehicle.businessEntity"
                                                      placeholder="Proveedor" label="legalName" :clearable="false">
                                                <template #no-options>Sin resultados.</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4" class="text-left" v-if="vehicle.type === 'RENT'">
                                    <ValidationProvider rules="required|double" v-slot="{ errors }">
                                        <b-form-group label="Costo" invalid-feedback="Ingresa el costo de la unidad">
                                            <b-form-input v-model="vehicle.amount1" autocomplete="false" placeholder="Costo"
                                                          :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button variant="outline-secondary" @click="cancelVehicle()" class="mr-2">Cancelar</b-button>
                            <b-button type="submit" form="vehicle-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                        </div>
                    </template>
                </b-modal>
            </template>

            <!-- external service modal -->
            <template>
                <b-modal id="external-service-modal" centered size="xl" title="Registro de Servicio Externo" hide-header-close no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(externalService)" id="external-service-form">
                            <b-row class="mb-2">
                                <b-col cols="12" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Tipo de pago">
                                            <b-form-radio-group v-model="vehicle.paymentType" :options="paymentTypes" :state="errors[0] ? false : null">
                                                <b-form-invalid-feedback :state="errors[0] ? false : null">Ingresa el tipo de pago</b-form-invalid-feedback>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="6" class="text-left">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Proveedor" invalid-feedback="Selecciona el proveedor" :state="errors[0] ? false : null">
                                            <v-select :options="businessEntitySuppliers" @search:focus="getBusinessEntity" @search="getBusinessEntity" v-model="vehicle.businessEntity"
                                                      placeholder="Proveedor" label="legalName" :clearable="false">
                                                <template #no-options>Sin resultados.</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col cols="12" md="6" class="text-left">
                                    <ValidationProvider rules="required|double" v-slot="{ errors }">
                                        <b-form-group label="Tarifa Adultos" invalid-feedback="Ingresa la tarifa de adultos">
                                            <b-form-input v-model="vehicle.amount1" autocomplete="false" placeholder="Tarifa Adultos"
                                                          :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="6" class="text-left">
                                    <ValidationProvider rules="required|double" v-slot="{ errors }">
                                        <b-form-group label="Tarifa Niños" invalid-feedback="Ingresa la tarifa de niños">
                                            <b-form-input v-model="vehicle.amount2" autocomplete="false" placeholder="Tarifa Niños"
                                                          :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button variant="outline-secondary" @click="cancelExternalService()" class="mr-2">Cancelar</b-button>
                            <b-button type="submit" form="external-service-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                        </div>
                    </template>
                </b-modal>
            </template>

            <!-- expense modal -->
            <template>
                <b-modal id="expense-modal" centered size="xl"  title="Registro de Gastos" hide-header-close no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(acceptExpenses)" id="expense-form">
                            <h3><b-button class="button-sticky-right" @click="addExpense"><font-awesome-icon icon="plus"></font-awesome-icon> Nuevo</b-button></h3>
                            <b-row>
                                <b-col>
                                    <b-table-simple responsive bordered striped>
                                        <b-thead>
                                            <b-tr>
                                                <b-th style="width: 14%">Concepto</b-th>
                                                <b-th style="width: 20%">Observaciones</b-th>
<!--                                                <b-th>Tipo de pago</b-th>-->
                                                <b-th>Registrado por</b-th>
                                                <b-th>Fecha de registro</b-th>
                                                <b-th style="text-align: right; width: 10%">Cantidad</b-th>
                                                <b-th style="text-align: right; width: 10%">Costo Unitario</b-th>
                                                <b-th style="text-align: right; width: 10%">Subtotal</b-th>
                                                <b-th>Acciones</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(expense, index) in serviceOrder.expenseList" :key="'expense-' + index" :class="{'text-danger': expense.isCancelled}" :style="[expense.isCancelled ? {'text-decoration': 'line-through'} : '']">
                                                <template v-if="expense.id">
                                                    <b-th>{{ expense.name }}</b-th>
                                                    <b-td>{{ expense.notes }}</b-td>
<!--                                                    <b-td>-->
<!--                                                        <span v-if="expense.paymentType === 'CASH'">Contado</span>-->
<!--                                                        <span v-if="expense.paymentType === 'CREDIY'">Crédito</span>-->
<!--                                                    </b-td>-->
                                                    <b-td>{{ expense.createdBy }}</b-td>
                                                    <b-td>{{ formatDateTime(expense.createdDate) }}</b-td>
                                                    <b-td style="text-align: right">{{ expense.qty }}</b-td>
                                                    <template v-if="expense.name === 'Gasolina'">
                                                        <b-td>
                                                            <b-form-input v-model="expense.unitPrice" autocomplete="false" :placeholder="`Costo`" style="text-align: right" @keyup="calculateExpenseTotal()"></b-form-input>
                                                        </b-td>
                                                    </template>
                                                    <template v-else>
                                                        <b-td style="text-align: right">$ {{ formatCurrency(expense.unitPrice) }}</b-td>
                                                    </template>
                                                    <b-td style="text-align: right">${{ formatCurrency(expense.total) }}</b-td>
                                                </template>
                                                <template v-else>
                                                    <b-th>
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <b-form-input v-model="expense.name" autocomplete="false" :placeholder="`Concepto`" :state="errors[0] ? false : null"></b-form-input>
                                                        </ValidationProvider>
                                                    </b-th>
                                                    <b-td>
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <b-form-input v-model="expense.notes" autocomplete="false" :placeholder="`Observaciones`" :state="errors[0] ? false : null"></b-form-input>
                                                        </ValidationProvider>
                                                    </b-td>
<!--                                                    <b-td>-->
<!--                                                        <ValidationProvider rules="required" v-slot="{ errors }">-->
<!--                                                            <v-select v-model="expense.paymentType" :options="paymentTypes" :placeholder="`Tipo`" :state="errors[0] ? false : null"-->
<!--                                                                       label="text" :clearable="false" class="paymentType">-->
<!--                                                                <template #no-options>Sin resultados.</template>-->
<!--                                                            </v-select>-->
<!--                                                        </ValidationProvider>-->
<!--                                                    </b-td>-->
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td style="text-align: right">{{ expense.qty }}</b-td>
                                                    <b-td>
                                                        <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                            <b-form-input v-model="expense.unitPrice" autocomplete="false" :placeholder="`Costo Unitario`" style="text-align: right" @keyup="calculateExpenseTotal()" :state="errors[0] ? false : null"></b-form-input>
                                                        </ValidationProvider>
                                                    </b-td>
                                                    <b-td style="text-align: right">${{ formatCurrency(expense.total) }}</b-td>
                                                </template>
                                                <b-td style="width: 40px">
                                                    <b-button variant="outline-danger" @click="deleteExpense(expense)" v-b-tooltip.hover.top title="Eliminar" v-if="expense.isExtra && !expense.isCancelled">
                                                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                    </b-button>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>Total</b-th>
                                                <b-th style="text-align: right" colspan="6">${{ formatCurrency(expenseTotal) }}</b-th>
                                                <b-th></b-th>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button variant="outline-secondary" @click="cancelExpenses" class="mr-2">Cancelar</b-button>
                            <b-button type="submit" form="expense-form" variant="success"> Continuar</b-button>
                        </div>
                    </template>
                </b-modal>
            </template>

            <!-- cancel modal -->
            <template>
                <b-modal id="cancel-modal" centered title="Pasajeros que cancelan servicio" hide-header-close no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(cancelBussinessOrderItem)" id="cancel-form">
                            <b-row>
                                <b-col>
                                    <template v-for="(businessOrderItem, index) in businessOrderItemList">
                                        <b-table-simple responsive bordered striped :key="'business-order-item' + index">
                                            <b-thead>
                                                <b-tr>
                                                    <b-th colspan="3">Servicio {{businessOrderItem.name}} {{businessOrderItem.folio}} </b-th>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th style="width: 5%"></b-th>
                                                    <b-th style="width: 8%">Tipo</b-th>
                                                    <b-th>Nombre</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                                <b-tr v-for="passenger in businessOrderItem.passengers" :key="passenger.id">
                                                    <b-td class="text-center">
                                                        <b-form-checkbox v-model="passenger.cancel" @input="updatePassengers(businessOrderItem)"
                                                                         :disabled="passenger.assigned"></b-form-checkbox>
                                                    </b-td>
                                                    <b-td>{{ passenger.type === 'ADULT' ? 'Adulto' : 'Niño' }}</b-td>
                                                    <b-td>{{ passenger.name }} {{ passenger.firstLastName }} {{ passenger.secondLastName }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </template>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Motivo de la cancelación" invalid-feedback="Ingresa el motivo de la cancelación">
                                            <b-form-textarea placeholder="Motivo de la cancelación" autocomplete="false" v-model="cancelMessage" :state="errors[0] ? false : null" ></b-form-textarea>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button variant="outline-secondary" @click="$bvModal.hide('cancel-modal')" class="mr-2">Cancelar</b-button>
                            <b-button type="submit" form="cancel-form" variant="success"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                        </div>
                    </template>
                </b-modal>
            </template>

            <!-- split business modal -->
            <template>
                <b-modal id="split-business-modal" centered title="Pasajeros que se asignaran al servicio" hide-header-close no-close-on-backdrop no-close-on-esc>
                    <b-form @submit.prevent="businessOrderItemSplit" id="split-business-form">
                        <b-row class="mt-4">
                            <b-col>
                                <template v-for="(businessOrderItem, index) in selectedDataToAssign">
                                    <b-table-simple responsive bordered striped :key="'business-order-item' + index">
                                        <b-thead>
                                            <b-tr>
                                                <b-th colspan="3">Servicio {{businessOrderItem.serviceName}} {{businessOrderItem.folio}} </b-th>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 5%"></b-th>
                                                <b-th style="width: 8%">Tipo</b-th>
                                                <b-th>Nombre</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="passenger in businessOrderItem.passengers" :key="passenger.id">
                                                <template v-if="!passenger.cancel && passenger.selected">
                                                    <td>
                                                        <template v-if="passenger.serviceOrderId == serviceOrder.id || passenger.serviceOrderId == 0">
                                                            <b-form-checkbox v-model="passenger.assigned" :disabled="passenger.serviceOrderId != 0" @input="updateBusinessPassengers(businessOrderItem)"></b-form-checkbox>
                                                        </template>
                                                    </td>
                                                    <td>{{ passenger.type === 'ADULT' ? 'Adulto' : 'Niño' }}</td>
                                                    <td>{{ passenger.name }} {{ passenger.firstLastName }} {{ passenger.secondLastName }}</td>
                                                </template>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </template>
                            </b-col>
                        </b-row>
                    </b-form>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button variant="outline-secondary" @click="cancelSplitBusiness" class="mr-2">Cancelar</b-button>
                            <b-button type="submit" form="split-business-form" variant="success"> Continuar</b-button>
                        </div>
                    </template>
                </b-modal>
            </template>

            <!-- split service modal -->
            <template>
                <b-modal id="split-service-modal" centered title="Pasajeros asignados al servicio" hide-header-close no-close-on-backdrop no-close-on-esc>
                    <b-form @submit.prevent="serviceOrderItemSplit" id="split-service-form">
                        <b-row class="mt-4">
                            <b-col>
                                <template v-for="(serviceOrderItem, index) in selectedServiceOrderItems">
                                    <b-table-simple responsive bordered striped :key="'business-order-item' + index">
                                        <b-thead>
                                            <b-tr>
                                                <b-th colspan="3">Servicio {{serviceOrderItem.businessOrderItem.serviceName}} {{serviceOrderItem.businessOrderItem.folio}} </b-th>
                                            </b-tr>
                                            <b-tr>
                                                <b-th style="width: 5%"></b-th>
                                                <b-th style="width: 8%">Tipo</b-th>
                                                <b-th>Nombre</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="passenger in serviceOrderItem.businessOrderItem.passengers" :key="passenger.id">
                                                <template v-if="!passenger.cancel && passenger.selected">
                                                    <td>
                                                        <template v-if="passenger.serviceOrderId == serviceOrder.id || passenger.serviceOrderId == 0">
                                                            <b-form-checkbox v-model="passenger.assigned" @input="updateServicePassengers(serviceOrderItem, passenger)"></b-form-checkbox>
                                                        </template>
                                                    </td>
                                                    <td>{{ passenger.type === 'ADULT' ? 'Adulto' : 'Niño' }}</td>
                                                    <td>{{ passenger.name }} {{ passenger.firstLastName }} {{ passenger.secondLastName }}</td>
                                                </template>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </template>
                            </b-col>
                        </b-row>
                    </b-form>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button variant="outline-secondary" @click="cancelSplitService" class="mr-2">Cancelar</b-button>
                            <b-button type="submit" form="split-service-form" variant="success"> Continuar</b-button>
                        </div>
                    </template>
                </b-modal>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import {
    userSelectUrl, businessOrderItemDataToAssignUrl, vehicleAvailabilityUrl, serviceOrderUrl, serviceOrderItemDataToServiceUrl,
    serviceOrderScheduleUrl, serviceOrderStatusUrl, serviceOrderLockUrl, vehicleUrl, vehicleEnableUrl, servicePageUrl,
    serviceOrderExpenseDataUrl, serviceOrderExpenseUrl, businessOrderItemCancelUrl, businessOrderItemPassengersUrl,
    businessEntitySelectUrl, paymentExpenseUrl, serviceOrderRemoveUrl, serviceOrderCancelUrl
} from '../../js/routes';
import { datePickerLabels } from '../../i18n/datePickerI18n';
import { ServiceOrder } from '../../models/serviceOrder';
import { ServiceOrderItem } from '../../models/serviceOrderItem';
const dayjs = require('dayjs');
import * as constants from '@constants';
import { timePickerLabels } from '../../i18n/timePickerI18n';
import { extend } from 'vee-validate';
import { required, integer, double, regex } from 'vee-validate/dist/rules';
import { Expense } from '../../models/expense';
import { formatCurrency, formatDate, formatDateTime } from '../../js/utilities';
import { Passenger } from '../../models/passenger';
import { BusinessOrderItem } from '../../models/businessOrderItem';
import Inputmask from 'inputmask';
const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('integer', {
    ...integer,
    message: 'Ingrese solo números'
});

extend('double', {
    ...double,
    message: 'Ingrese solo números y decimales'
});

extend('regex', {
    ...regex,
    message: 'Ingresa un valor válido'
});

export default {
    data() {
        return {
            swalTitle: 'Operaciones',
            breadcrumb: {
                title: 'Operaciones',
                path: []
            },
            filters: {
                date: '',
                businessEntity: null,
                hotel: null,
                flightSchedule: '',
                flightNumber: '',
                service: null
            },
            isLoading: false,
            datePickerLabels: datePickerLabels.es,
            businessEntities: [],
            hotels: [],
            flightSchedules: [],
            flightNumbers: [],
            services: [],
            users: [],
            dataToAssign: [],
            availableVehicles: [],
            dataServiceOrderItems: [],
            serviceOrder: null,
            scheduleServiceOrder: null,
            serviceOrders: [],
            timePickerLabels: timePickerLabels.es,
            vehicle: {
                id: 0,
                brand: '',
                model: '',
                year: '',
                licensePlate: '',
                passengerCapacity: '',
                type: '',
                businessEntity: '',
                paymentType: '',
                amount1: 0,
                amount2: 0
            },
            vehicleTypes: [
                { text: 'Propio', value: 'OWN' },
                { text: 'Alquilado', value: 'RENT' }
            ],
            paymentTypes: [
                { text: 'Contado', value: 'CASH' },
                { text: 'Crédito', value: 'CREDIT' }
            ],
            expenseList: [],
            // serviceOrdersUnassigned: [],
            // unassignedCount: 0,
            expenseTotal: 0,
            businessOrderItemList: [],
            cancelMessage: '',
            serviceOrderItemCache: [],
            dataToAssignCache: [],
            expenseListCache: [],
            businessEntitySuppliers: []
        };
    },
    created() {
        this.filters.date = dayjs().format('YYYY-MM-DD');
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.serviceOrder = ServiceOrder.create();
        this.scheduleServiceOrder = ServiceOrder.create();
        this.getUsers();
        // this.getServiceOrdersUnassigned();
    },
    mounted() {
        this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
            if (modalId === 'schedule-modal') {
                this.$nextTick(() => {
                    Inputmask().mask(document.querySelectorAll('.inputmask'));
                });
            }

            if (modalId === 'split-service-modal') {
                this.serviceOrderItemCache = [];
                this.selectedServiceOrderItems.forEach(item => {
                    let serviceOrderItem = ServiceOrderItem.create();

                    serviceOrderItem.id = item.id;
                    serviceOrderItem.guid = item.guid;
                    serviceOrderItem.businessOrderItem = JSON.parse(JSON.stringify(item.businessOrderItem));

                    this.serviceOrderItemCache.push(serviceOrderItem);
                });
            }

            if (modalId === 'split-business-modal') {
                this.dataToAssignCache = [];
                this.selectedDataToAssign.forEach(item => {
                    let businessOrderItem =  { ...item };
                    businessOrderItem.passengers = JSON.parse(JSON.stringify(item.passengers));
                    this.dataToAssignCache.push(businessOrderItem);
                });
            }

            if (modalId === 'expense-modal') {
                this.expenseListCache = [];
                this.serviceOrder.expenseList.forEach(item => {
                    let expenseLis =  { ...item };
                    this.expenseListCache.push(expenseLis);
                });
            }
        });
    },
    methods: {
        formatCurrency,
        formatDate,
        formatDateTime,
        getDataToAssign(loadFilters) {
            return this.axios.get(businessOrderItemDataToAssignUrl(), {
                params: this.filters
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.dataToAssign = response.data.data;

                    response.data.data.forEach(item => {
                        let passengersList = [];
                        let businessOrderItemPassengers = JSON.parse(item.passengers);

                        businessOrderItemPassengers.forEach(passengerData => {
                            let passenger = Passenger.create(passengerData.type);

                            passenger.id = passengerData.id;
                            passenger.name = passengerData.name;
                            passenger.firstLastName = passengerData.firstLastName;
                            passenger.secondLastName = passengerData.secondLastName;
                            passenger.identifier = passengerData.id.toString();
                            passenger.selected = passengerData.selected;
                            passenger.assigned = passengerData.assigned;
                            passenger.cancel = passengerData.cancel;
                            passenger.serviceOrderId = passengerData.serviceOrderId;

                            passengersList.push(passenger);
                        });

                        item.passengers = passengersList;
                    });

                    if (this.serviceOrder.serviceOrderItems.length > 0) {
                        this.serviceOrder.serviceOrderItems.forEach(serviceOrderItem => {
                            let dataToAssignIndex = this.dataToAssign.findIndex(objectData => parseInt(objectData.businessOrderItemId) === parseInt(serviceOrderItem.businessOrderItem.businessOrderItemId));

                            if (dataToAssignIndex >= 0 && serviceOrderItem.businessOrderItem.status != 'PARTIAL_ASSIGN') {
                                this.dataToAssign.splice(dataToAssignIndex, 1);
                            }
                        });
                    }
                    if (loadFilters) {
                        this.loadFiltersData();
                    }
                }
            });
        },
        getDataToServiceOrder(serviceOrder) {
            return this.axios.get(serviceOrderItemDataToServiceUrl(serviceOrder.id), {
                // params: this.filters
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.dataServiceOrderItems = response.data.data;

                    this.dataServiceOrderItems.forEach(data => {

                        let serviceOrderItem = ServiceOrderItem.create();

                        serviceOrderItem.businessOrderItem = JSON.parse(JSON.stringify(data));
                        serviceOrderItem.adults = data.serviceOrderItemAdults;
                        serviceOrderItem.children = data.serviceOrderItemChildren;
                        serviceOrderItem.id = data.serviceOrderItemId;

                        let passengersList = [];
                        let businessOrderItemPassengers = JSON.parse(data.passengers);

                        businessOrderItemPassengers.forEach(passengerData => {
                            let passenger = Passenger.create(passengerData.type);

                            passenger.id = passengerData.id;
                            passenger.name = passengerData.name;
                            passenger.firstLastName = passengerData.firstLastName;
                            passenger.secondLastName = passengerData.secondLastName;
                            passenger.identifier = passengerData.id.toString();
                            passenger.selected = passengerData.selected;
                            passenger.assigned = passengerData.assigned;
                            passenger.cancel = passengerData.cancel;
                            passenger.serviceOrderId = passengerData.serviceOrderId;

                            passengersList.push(passenger);
                        });

                        serviceOrderItem.businessOrderItem.passengers = passengersList;

                        serviceOrder.serviceOrderItems.push(serviceOrderItem);
                        serviceOrder.availabilityPassengers = this.serviceOrder.availabilityPassengers - (serviceOrderItem.adults + serviceOrderItem.children);
                    });
                }
            });
        },
        // getServiceOrdersUnassigned() {
        //     this.axios.get(serviceOrdersUnassignedUrl()).then(response => {
        //         if (response.data.code === constants.CODE_OK) {
        //             this.serviceOrdersUnassigned = response.data.data;
        //             this.unassignedCount = this.serviceOrdersUnassigned.length;
        //         }
        //     }).finally(() => {
        //         this.isLoading = false;
        //     });
        // },
        loadFiltersData() {
            // this.filters.businessEntity = null;
            // this.filters.hotel = null;
            // this.filters.flightSchedule = '';
            // this.filters.flightNumber = '';
            // this.filters.service = null;

            this.businessEntities = [];
            this.hotels = [];
            this.flightSchedules = [];
            this.flightNumbers = [];
            this.services = [];

            this.dataToAssign.forEach(data => {
                let businessEntityFound = this.businessEntities.findIndex(object => parseInt(object.id) === parseInt(data.businessEntityId));

                if (businessEntityFound < 0) {
                    this.businessEntities.push({
                        id: data.businessEntityId,
                        name: data.businessEntityName
                    });
                }

                if (data.hotel1 !== '') {
                    let hotelFound1 = this.hotels.findIndex(object => object === data.hotel1);

                    if (hotelFound1 < 0) {
                        this.hotels.push(data.hotel1);
                    }
                }

                if (data.hotel2 !== '') {
                    let hotelFound2 = this.hotels.findIndex(object => object === data.hotel2);

                    if (hotelFound2 < 0) {
                        this.hotels.push(data.hotel2);
                    }
                }

                if (data.flightSchedule !== '') {
                    let flightScheduleFound = this.flightSchedules.findIndex(object => object === data.flightSchedule);

                    if (flightScheduleFound < 0) {
                        this.flightSchedules.push(data.flightSchedule);
                    }
                }

                if (data.flightNumber !== '') {
                    let flightNumberFound = this.flightNumbers.findIndex(object => object === data.flightNumber);

                    if (flightNumberFound < 0) {
                        this.flightNumbers.push(data.flightNumber);
                    }
                }

                let serviceFound = this.services.findIndex(object => parseInt(object.id) === parseInt(data.serviceId));

                if (serviceFound < 0) {
                    this.services.push({
                        id: data.serviceId,
                        name: data.serviceName
                    });
                }
            });
        },
        getUsers(search, loading) {
            if (loading) {
                loading(true);
            }

            this.axios.get(userSelectUrl(), { params: { search, date: this.filters.date }}).then(response => {
                this.users = response.data;
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al listar los usuarios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });

                this.users = [];
            }).finally(() => {
                if (loading) {
                    loading(false);
                }
            });
        },
        getAvailability() {
            return this.axios.get(vehicleAvailabilityUrl(), {
                params: this.filters
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.availableVehicles = response.data.data;
                    this.serviceOrders = this.availableVehicles.map(availableVehicle => availableVehicle.serviceOrders).flat();
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        getBusinessEntity(search, loading) {
            if (loading) {
                loading(true);
            }

            this.axios.get(businessEntitySelectUrl(), { params: {
                search: search,
                isSupplier: true
            }}).then(response => {
                this.businessEntitySuppliers = response.data;
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al listar los proveedores.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });

                this.businessEntitySuppliers = [];
            }).finally(() => {
                if (loading) {
                    loading(false);
                }
            });
        },
        selectedVehicle(availableVehicle) {
            this.isLoading = true;

            if (this.activeVehicle) {
                this.activeVehicle.isActive = false;
            }

            availableVehicle.isActive = true;

            if (this.activeServiceOrder) {
                this.activeServiceOrder.isActive = false;
            }

            this.serviceOrder = ServiceOrder.create();
            // this.serviceOrder.adults = availableVehicle.adults;
            // this.serviceOrder.children = availableVehicle.children;
            this.serviceOrder.vehicleId = availableVehicle.id;
            this.serviceOrder.vehicleYear = availableVehicle.year;
            this.serviceOrder.vehicleBrand = availableVehicle.brand;
            this.serviceOrder.vehicleModel = availableVehicle.model;
            this.serviceOrder.vehicleLicensePlate = availableVehicle.licensePlate;
            this.serviceOrder.passengerCapacity = availableVehicle.passengerCapacity;
            // this.serviceOrder.availabilityPassengers = availableVehicle.availabilityPassengers;
            this.serviceOrder.availabilityPassengers = availableVehicle.passengerCapacity;

            this.getDataToAssign(true).then(() => {
                this.isLoading = false;
            });
        },
        selectedServiceOrder(serviceOrder, availableVehicle) {
            this.isLoading = true;

            if (this.activeVehicle) {
                this.activeVehicle.isActive = false;
            }

            availableVehicle.isActive = true;

            if (this.activeServiceOrder) {
                this.activeServiceOrder.isActive = false;
            }

            serviceOrder.isActive = true;

            this.serviceOrder = ServiceOrder.create();

            if (serviceOrder.id) {
                this.serviceOrder.id = serviceOrder.id;
            }

            this.serviceOrder.vehicleId = availableVehicle.id;
            this.serviceOrder.vehicleYear = availableVehicle.year;
            this.serviceOrder.vehicleBrand = availableVehicle.brand;
            this.serviceOrder.vehicleModel = availableVehicle.model;
            this.serviceOrder.vehicleLicensePlate = availableVehicle.licensePlate;
            this.serviceOrder.passengerCapacity = availableVehicle.passengerCapacity;
            this.serviceOrder.availabilityPassengers = availableVehicle.passengerCapacity;
            this.serviceOrder.serviceId = serviceOrder.serviceId;
            this.serviceOrder.serviceName = serviceOrder.serviceName;
            this.serviceOrder.serviceDescription = serviceOrder.serviceDescription;
            this.serviceOrder.serviceType = serviceOrder.serviceType;
            this.serviceOrder.adultsQty = serviceOrder.adultsQty;
            this.serviceOrder.childrenQty = serviceOrder.childrenQty;
            this.serviceOrder.operatorId = serviceOrder.operatorId;
            this.serviceOrder.serviceDurationType = serviceOrder.serviceDurationType;
            this.serviceOrder.serviceStartTime = serviceOrder.serviceStartTime;
            this.serviceOrder.serviceEndTime = serviceOrder.serviceEndTime;
            this.serviceOrder.startDate = serviceOrder.startDate;
            this.serviceOrder.endDate = serviceOrder.endDate;
            this.serviceOrder.startTime = serviceOrder.startTime;
            this.serviceOrder.endTime = serviceOrder.endTime;
            this.serviceOrder.folio = serviceOrder.folio;
            this.serviceOrder.status = serviceOrder.status;
            this.serviceOrder.statusLock = serviceOrder.statusLock;
            this.serviceOrder.supplierId = serviceOrder.supplierId;

            Promise.all([this.getDataToAssign(true), this.getDataToServiceOrder(this.serviceOrder), this.getExpenseData()]).finally(() => {
                this.isLoading = false;
            });
        },
        addServiceOrderItem(allItems) {
            let passengersTotal = 0;
            let itemsDataToAssign = [];

            if (allItems) {
                passengersTotal = (this.adultsToAssign + this.childrenToAssign);
                itemsDataToAssign = JSON.parse(JSON.stringify(this.dataToAssign));
            } else {
                passengersTotal = (this.selectedAdultsToAssign + this.selectedChildrenToAssign);
                itemsDataToAssign = JSON.parse(JSON.stringify(this.selectedDataToAssign));
            }

            if (this.isValidServiceData(itemsDataToAssign)) {
                if (this.isValidServiceDate(itemsDataToAssign)) {
                    if (this.isValidServiceType(itemsDataToAssign)) {
                        if (passengersTotal <= this.serviceOrder.availabilityPassengers) {
                            if (this.serviceOrder.serviceId <= 0) {
                                let dataToAssign = itemsDataToAssign[0];

                                this.serviceOrder.serviceId = dataToAssign.serviceId;
                                this.serviceOrder.serviceName = dataToAssign.serviceName;
                                this.serviceOrder.serviceDescription = dataToAssign.serviceDescription;
                                this.serviceOrder.serviceType = dataToAssign.type;
                                this.serviceOrder.serviceStartTime = dataToAssign.startTime;
                                this.serviceOrder.serviceEndTime = dataToAssign.endTime;
                                this.serviceOrder.startDate = dataToAssign.startDate;
                                this.serviceOrder.endDate = dataToAssign.endDate;
                            }

                            itemsDataToAssign.forEach(data => {
                                if (data.availableAdults || data.availableChildren) {
                                    let serviceOrderItemIndex = this.serviceOrder.serviceOrderItems.findIndex(object => object.businessOrderItem.businessOrderItemId === data.businessOrderItemId);

                                    let passengersList = [];
                                    let serviceOrderItem = ServiceOrderItem.create();

                                    if (data.serviceOrderItemId) {
                                        serviceOrderItem.id = data.serviceOrderItemId;
                                    }

                                    data.passengers.forEach(passengerData => {
                                        let passenger = Passenger.create(passengerData.type);

                                        passenger.id = passengerData.id;
                                        passenger.name = passengerData.name;
                                        passenger.firstLastName = passengerData.firstLastName;
                                        passenger.secondLastName = passengerData.secondLastName;
                                        passenger.identifier = passengerData.id.toString();
                                        passenger.selected = passengerData.selected;
                                        passenger.assigned = passengerData.cancel ? false : true;
                                        passenger.cancel = passengerData.cancel;
                                        passenger.serviceOrderId = passengerData.serviceOrderId;

                                        passengersList.push(passenger);
                                    });

                                    data.passengers = passengersList;

                                    serviceOrderItem.businessOrderItem = JSON.parse(JSON.stringify(data));
                                    serviceOrderItem.adults = data.availableAdults;
                                    serviceOrderItem.children = data.availableChildren;
                                    serviceOrderItem.businessOrderItem.availableAdults = 0;
                                    serviceOrderItem.businessOrderItem.availableChildren = 0;
                                    serviceOrderItem.businessOrderItem.assignedAdults = data.availableAdults;
                                    serviceOrderItem.businessOrderItem.assignedChildren = data.availableChildren;

                                    if (serviceOrderItemIndex >= 0) {
                                        this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].adults = this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].adults + serviceOrderItem.adults;
                                        this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].children = this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].children + serviceOrderItem.children;
                                        this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].businessOrderItem = serviceOrderItem.businessOrderItem;
                                    } else {
                                        this.serviceOrder.serviceOrderItems.push(serviceOrderItem);
                                    }
                                    this.serviceOrder.availabilityPassengers = this.serviceOrder.availabilityPassengers - (serviceOrderItem.adults + serviceOrderItem.children);
                                    this.serviceOrder.adultsQty = this.serviceOrder.adultsQty + data.availableAdults;
                                    this.serviceOrder.childrenQty = this.serviceOrder.childrenQty + data.availableChildren;

                                    let dataToAssignIndex = this.dataToAssign.findIndex(objectData => parseInt(objectData.businessOrderItemId) === parseInt(data.businessOrderItemId));

                                    if (dataToAssignIndex >= 0) {
                                        this.dataToAssign.splice(dataToAssignIndex, 1);
                                    }

                                    this.getExpenseData();
                                    this.updateExpensesQty();
                                }
                            });
                        } else {
                            this.$bvToast.toast('La cantidad de pasajeros excede la disponibilidad del vehiculo.', {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    } else {
                        this.$bvToast.toast('Las reservaciones de tipo exclusivo no se pueden asignar con otras.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }
                } else {
                    this.$bvToast.toast('Los servicios seleccionados tienen distintas fechas de inicio', {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            } else {
                let serviceMessage = 'Multiples servicios seleccionados: ';

                let sevices = itemsDataToAssign.map(item =>{
                    return [item.serviceName, item];
                });

                let servicesMap = new Map(sevices);

                let servicesNames = [...servicesMap.values()];

                for (let service of servicesNames) {
                    if (service == servicesNames[0]) {
                        serviceMessage = serviceMessage.concat(service.serviceName);
                    } else {
                        serviceMessage = serviceMessage.concat(', ' + service.serviceName);
                    }
                }

                this.$bvToast.toast(serviceMessage, {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }
        },
        removeServiceOrderItem(allItems) {
            let serviceOrderItemsData = [];

            if (allItems) {
                serviceOrderItemsData = JSON.parse(JSON.stringify(this.serviceOrder.serviceOrderItems));
            } else {
                serviceOrderItemsData = JSON.parse(JSON.stringify(this.selectedServiceOrderItems));
            }

            serviceOrderItemsData.forEach(serviceOrderItem => {
                let passengersList = [];
                let serviceOrderItemIndex = this.serviceOrder.serviceOrderItems.findIndex(object => object.guid === serviceOrderItem.guid);
                let dataToAssignIndex = this.dataToAssign.findIndex(object => object.businessOrderItemId === serviceOrderItem.businessOrderItem.businessOrderItemId);

                serviceOrderItem.businessOrderItem.passengers.forEach(passengerData => {
                    let passenger = Passenger.create(passengerData.type);

                    passenger.id = passengerData.id;
                    passenger.name = passengerData.name;
                    passenger.firstLastName = passengerData.firstLastName;
                    passenger.secondLastName = passengerData.secondLastName;
                    passenger.identifier = passengerData.id.toString();
                    passenger.selected = passengerData.selected;
                    passenger.assigned = passengerData.serviceOrderId == this.serviceOrder.id || passengerData.serviceOrderId == 0 ? false : passengerData.assigned;
                    passenger.cancel = passengerData.cancel;
                    passenger.serviceOrderId = passengerData.serviceOrderId == this.serviceOrder.id ? 0 : passengerData.serviceOrderId;

                    passengersList.push(passenger);
                });

                serviceOrderItem.businessOrderItem.passengers = passengersList;
                serviceOrderItem.businessOrderItem.availableAdults = serviceOrderItem.adults;
                serviceOrderItem.businessOrderItem.availableChildren = serviceOrderItem.children;

                if (dataToAssignIndex >= 0) {
                    this.dataToAssign[dataToAssignIndex].availableAdults = this.dataToAssign[dataToAssignIndex].availableAdults + serviceOrderItem.adults;
                    this.dataToAssign[dataToAssignIndex].availableChildren = this.dataToAssign[dataToAssignIndex].availableChildren + serviceOrderItem.children;
                    this.dataToAssign[dataToAssignIndex].passengers = serviceOrderItem.businessOrderItem.passengers;
                    this.dataToAssign[dataToAssignIndex].serviceOrderItemId = serviceOrderItem.id;
                } else {
                    this.dataToAssign.push(serviceOrderItem.businessOrderItem);
                }

                if (serviceOrderItemIndex >= 0) {
                    this.serviceOrder.serviceOrderItems.splice(serviceOrderItemIndex, 1);
                    this.serviceOrder.availabilityPassengers = this.serviceOrder.availabilityPassengers + (serviceOrderItem.adults + serviceOrderItem.children);
                    this.serviceOrder.adultsQty = this.serviceOrder.adultsQty - serviceOrderItem.adults;
                    this.serviceOrder.childrenQty = this.serviceOrder.childrenQty - serviceOrderItem.children;
                }
            });

            this.updateExpensesQty();
            this.calculateExpenseTotal();
        },
        businessOrderItemSplit() {
            let passengersTotal = 0;
            let itemsDataToAssign = [];

            passengersTotal = (this.selectedAdultsToAssign + this.selectedChildrenToAssign);
            itemsDataToAssign = JSON.parse(JSON.stringify(this.selectedDataToAssign));

            if (this.isValidServiceData(itemsDataToAssign)) {
                if (this.isValidServiceDate(itemsDataToAssign)) {
                    if (this.isValidServiceType(itemsDataToAssign)) {
                        let selectedPassengers = 0;

                        this.dataToAssign.forEach(data => {
                            selectedPassengers = selectedPassengers + data.passengers.filter(object => object.assigned && (object.serviceOrderId == 0 || object.serviceOrderId == this.serviceOrder.id)).length;
                        });

                        if (this.serviceOrder.passengerCapacity >= selectedPassengers) {
                            if (this.serviceOrder.serviceId <= 0) {
                                let dataToAssign = itemsDataToAssign[0];

                                this.serviceOrder.serviceId = dataToAssign.serviceId;
                                this.serviceOrder.serviceName = dataToAssign.serviceName;
                                this.serviceOrder.serviceDescription = dataToAssign.serviceDescription;
                                this.serviceOrder.serviceType = dataToAssign.type;
                                this.serviceOrder.startDate = dataToAssign.startDate;
                                this.serviceOrder.endDate = dataToAssign.endDate;
                            }

                            itemsDataToAssign.forEach(data => {
                                this.updateBusinessPassengers(data);
                                let dataToAssignIndex = this.dataToAssign.findIndex(objectData => parseInt(objectData.businessOrderItemId) === parseInt(data.businessOrderItemId));
                                let serviceOrderItemIndex = this.serviceOrder.serviceOrderItems.findIndex(object => object.businessOrderItem.businessOrderItemId === data.businessOrderItemId);
                                let serviceOrderItem = ServiceOrderItem.create();

                                if (data.serviceOrderItemId) {
                                    serviceOrderItem.id = data.serviceOrderItemId;
                                }

                                let availablePassengers = data.passengers.filter(object => (object.serviceOrderId <= 0 || object.serviceOrderId == this.serviceOrder.id) && !object.cancel);
                                let availableAdultsTotal = availablePassengers.filter(object => object.type === 'ADULT').length;
                                let availableChildrenTotal = availablePassengers.filter(object => object.type === 'CHILD').length;

                                data.availableAdults = availableAdultsTotal - data.assignedAdults;
                                data.availableChildren = availableChildrenTotal - data.assignedChildren;
                                serviceOrderItem.businessOrderItem = JSON.parse(JSON.stringify(data));
                                serviceOrderItem.adults = data.assignedAdults;
                                serviceOrderItem.children = data.assignedChildren;

                                if (serviceOrderItemIndex >= 0) {
                                    this.serviceOrder.adultsQty = this.serviceOrder.adultsQty - this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].adults;
                                    this.serviceOrder.childrenQty = this.serviceOrder.childrenQty - this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].children;
                                    this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].adults = serviceOrderItem.adults;
                                    this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].children = serviceOrderItem.children;
                                    this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].businessOrderItem = serviceOrderItem.businessOrderItem;

                                    if (serviceOrderItem.adults == 0 && serviceOrderItem.children == 0) {
                                        this.serviceOrder.serviceOrderItems.splice(serviceOrderItemIndex, 1);
                                    }
                                } else {
                                    if (serviceOrderItem.adults > 0 || serviceOrderItem.children > 0) {
                                        this.serviceOrder.serviceOrderItems.push(serviceOrderItem);
                                    }
                                }
                                this.serviceOrder.adultsQty = this.serviceOrder.adultsQty + data.assignedAdults;
                                this.serviceOrder.childrenQty = this.serviceOrder.childrenQty + data.assignedChildren;
                                this.serviceOrder.availabilityPassengers = this.serviceOrder.passengerCapacity - (this.serviceOrder.adultsQty + this.serviceOrder.childrenQty);

                                if (dataToAssignIndex >= 0) {
                                    this.dataToAssign[dataToAssignIndex].availableAdults = data.availableAdults;
                                    this.dataToAssign[dataToAssignIndex].availableChildren = data.availableChildren;

                                    if (this.dataToAssign[dataToAssignIndex].availableAdults == 0 && this.dataToAssign[dataToAssignIndex].availableChildren == 0) {
                                        this.dataToAssign.splice(dataToAssignIndex, 1);
                                    }
                                }

                                this.getExpenseData();
                                this.updateExpensesQty();
                            });

                            this.$nextTick(() => {
                                this.$bvModal.hide('split-business-modal');
                            });
                        } else {
                            this.$bvToast.toast('La cantidad de pasajeros excede la disponibilidad del vehiculo.', {
                                title: this.swalTitle,
                                variant: 'danger'
                            });

                            this.cancelSplitBusiness();
                        }
                    } else {
                        this.$bvToast.toast('Las reservaciones de tipo exclusivo no se pueden asignar con otras.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });

                        this.cancelSplitBusiness();
                    }
                } else {
                    this.$bvToast.toast('Los servicios seleccionados tienen distintas fechas de inicio', {
                        title: this.swalTitle,
                        variant: 'danger'
                    });

                    this.cancelSplitBusiness();
                }
            } else {
                let serviceMessage = 'Multiples servicios seleccionados: ';

                let sevices = itemsDataToAssign.map(item =>{
                    return [item.serviceName, item];
                });

                let servicesMap = new Map(sevices);

                let servicesNames = [...servicesMap.values()];

                for (let service of servicesNames) {
                    if (service == servicesNames[0]) {
                        serviceMessage = serviceMessage.concat(service.serviceName);
                    } else {
                        serviceMessage = serviceMessage.concat(', ' + service.serviceName);
                    }
                }

                this.$bvToast.toast(serviceMessage, {
                    title: this.swalTitle,
                    variant: 'danger'
                });

                this.cancelSplitBusiness();
            }
        },
        serviceOrderItemSplit() {
            let serviceOrderItemsData = [];

            serviceOrderItemsData = JSON.parse(JSON.stringify(this.selectedServiceOrderItems));

            let selectedPassengers = 0;

            this.serviceOrder.serviceOrderItems.forEach(data => {
                selectedPassengers = selectedPassengers + data.businessOrderItem.passengers.filter(object => object.assigned && (object.serviceOrderId == 0 || object.serviceOrderId == this.serviceOrder.id)).length;
            });

            if (this.serviceOrder.passengerCapacity >= selectedPassengers) {
                serviceOrderItemsData.forEach(serviceOrderItem => {
                    if (serviceOrderItem) {
                        let passengersList = [];
                        let serviceOrderItemIndex = this.serviceOrder.serviceOrderItems.findIndex(object => object.guid === serviceOrderItem.guid);
                        let dataToAssignIndex = this.dataToAssign.findIndex(object => object.businessOrderItemId === serviceOrderItem.businessOrderItem.businessOrderItemId);

                        serviceOrderItem.businessOrderItem.passengers.forEach(passengerData => {
                            let passenger = Passenger.create(passengerData.type);

                            passenger.id = passengerData.id;
                            passenger.name = passengerData.name;
                            passenger.firstLastName = passengerData.firstLastName;
                            passenger.secondLastName = passengerData.secondLastName;
                            passenger.identifier = passengerData.id.toString();
                            passenger.selected = passengerData.selected;
                            passenger.assigned = passengerData.assigned;
                            passenger.cancel = passengerData.cancel;
                            passenger.serviceOrderId = passengerData.serviceOrderId == this.serviceOrder.id && !passengerData.assigned ? 0 : passengerData.serviceOrderId;

                            passengersList.push(passenger);
                        });

                        let availablePassengers = serviceOrderItem.businessOrderItem.passengers.filter(object => !object.assigned && !object.cancel);
                        let availableAdultsTotal = availablePassengers.filter(object => object.type === 'ADULT').length;
                        let availableChildrenTotal = availablePassengers.filter(object => object.type === 'CHILD').length;

                        serviceOrderItem.businessOrderItem.passengers = passengersList;
                        serviceOrderItem.adults = serviceOrderItem.businessOrderItem.assignedAdults;
                        serviceOrderItem.children = serviceOrderItem.businessOrderItem.assignedChildren;
                        serviceOrderItem.businessOrderItem.availableAdults = availableAdultsTotal;
                        serviceOrderItem.businessOrderItem.availableChildren = availableChildrenTotal;

                        if (dataToAssignIndex >= 0) {
                            this.dataToAssign[dataToAssignIndex].availableAdults = serviceOrderItem.businessOrderItem.availableAdults;
                            this.dataToAssign[dataToAssignIndex].availableChildren = serviceOrderItem.businessOrderItem.availableChildren;
                            this.dataToAssign[dataToAssignIndex].passengers = serviceOrderItem.businessOrderItem.passengers;
                            this.dataToAssign[dataToAssignIndex].serviceOrderItemId = serviceOrderItem.id;

                            if (this.dataToAssign[dataToAssignIndex].availableAdults == 0 && this.dataToAssign[dataToAssignIndex].availableChildren == 0) {
                                this.dataToAssign.splice(dataToAssignIndex, 1);
                            }
                        } else {
                            if (serviceOrderItem.businessOrderItem.availableAdults > 0 || serviceOrderItem.businessOrderItem.availableChildren > 0) {
                                this.dataToAssign.push(serviceOrderItem.businessOrderItem);
                            }
                        }

                        if (serviceOrderItemIndex >= 0) {
                            this.serviceOrder.availabilityPassengers = this.serviceOrder.availabilityPassengers + ((this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].adults - serviceOrderItem.businessOrderItem.assignedAdults) + (this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].children - serviceOrderItem.businessOrderItem.assignedChildren));
                            this.serviceOrder.adultsQty = this.serviceOrder.adultsQty - (this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].adults - serviceOrderItem.businessOrderItem.assignedAdults);
                            this.serviceOrder.childrenQty = this.serviceOrder.childrenQty - (this.serviceOrder.serviceOrderItems[serviceOrderItemIndex].children - serviceOrderItem.businessOrderItem.assignedChildren);

                            if (serviceOrderItem.adults == 0 && serviceOrderItem.children == 0) {
                                this.serviceOrder.serviceOrderItems.splice(serviceOrderItemIndex, 1);
                            } else {
                                this.serviceOrder.serviceOrderItems.splice(serviceOrderItemIndex, 1, serviceOrderItem);
                            }
                        }
                    }
                });
            } else {
                this.$bvToast.toast('La cantidad de pasajeros excede la disponibilidad del vehiculo.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });

                this.cancelSplitService();
            }

            this.updateExpensesQty();
            this.calculateExpenseTotal();

            this.$nextTick(() => {
                this.$bvModal.hide('split-service-modal');
            });
        },
        updateBusinessPassengers(businessOrderItem) {
            let selectedPassengers = businessOrderItem.passengers.filter(passenger => passenger.assigned && (passenger.serviceOrderId == 0 || passenger.serviceOrderId == this.serviceOrder.id));

            businessOrderItem.assignedAdults = selectedPassengers.filter(passenger => passenger.type === 'ADULT').length;
            businessOrderItem.assignedChildren = selectedPassengers.filter(passenger => passenger.type === 'CHILD').length;
        },
        updateServicePassengers(serviceOrderItem) {
            let selectedPassengers = serviceOrderItem.businessOrderItem.passengers.filter(passenger => passenger.assigned &&  (passenger.serviceOrderId == 0 || passenger.serviceOrderId == this.serviceOrder.id));

            serviceOrderItem.businessOrderItem.assignedAdults = selectedPassengers.filter(passenger => passenger.type === 'ADULT').length;
            serviceOrderItem.businessOrderItem.assignedChildren = selectedPassengers.filter(passenger => passenger.type === 'CHILD').length;
        },
        cancelSplitBusiness() {
            this.dataToAssignCache.forEach(item => {
                let dataToAssignIndex = this.selectedDataToAssign.findIndex(object => object.businessOrderItemId === item.businessOrderItemId);

                if (dataToAssignIndex >= 0) {
                    this.selectedDataToAssign[dataToAssignIndex].assignedAdults = item.assignedAdults;
                    this.selectedDataToAssign[dataToAssignIndex].assignedChildren = item.assignedChildren;
                    this.selectedDataToAssign[dataToAssignIndex].passengers = item.passengers;
                }
            });

            this.$nextTick(() => {
                this.$bvModal.hide('split-business-modal');
            });
        },
        cancelSplitService() {
            this.serviceOrderItemCache.forEach(item => {
                let serviceOrderItemIndex = this.selectedServiceOrderItems.findIndex(object => object.guid === item.guid);

                if (serviceOrderItemIndex >= 0) {
                    this.selectedServiceOrderItems[serviceOrderItemIndex].businessOrderItem = item.businessOrderItem;
                }
            });

            this.$nextTick(() => {
                this.$bvModal.hide('split-service-modal');
            });
        },
        getPassengersToSelect() {
            let invalidItems = this.selectedDataToAssign.filter(selectedDataToAssign => selectedDataToAssign.status == 'ASSIGNED');

            if (invalidItems.length < 1) {
                this.axios.post(businessOrderItemPassengersUrl(), this.selectedDataToAssign).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.businessOrderItemList = [];
                        this.cancelMessage = '';

                        response.data.data.forEach(item => {
                            let businessOrderItem = BusinessOrderItem.create();
                            businessOrderItem.id = item.id;
                            businessOrderItem.name = item.name;
                            businessOrderItem.folio = item.folio;
                            businessOrderItem.adults = item.adults;
                            businessOrderItem.children = item.children;
                            businessOrderItem.cancelAdults = item.cancelAdults;
                            businessOrderItem.cancelChildren = item.cancelChildren;
                            businessOrderItem.assignedAdults = item.assignedAdults;
                            businessOrderItem.assignedChildren = item.assignedChildren;

                            let businessOrderItemPassengers = JSON.parse(item.passengersData);

                            businessOrderItemPassengers.forEach(passengerData => {
                                let passenger = Passenger.create(passengerData.type);

                                passenger.id = passengerData.id;
                                passenger.name = passengerData.name;
                                passenger.firstLastName = passengerData.firstLastName;
                                passenger.secondLastName = passengerData.secondLastName;
                                passenger.identifier = passengerData.id.toString();
                                passenger.selected = passengerData.selected;
                                passenger.assigned = passengerData.assigned;
                                passenger.cancel = passengerData.cancel;
                                passenger.serviceOrderId = passengerData.serviceOrderId;

                                businessOrderItem.passengers.push(passenger);
                            });

                            this.businessOrderItemList.push(businessOrderItem);
                        });

                        this.$bvModal.show('cancel-modal');
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            } else {
                this.$bvToast.toast('No se pueden cancelar servicios asignados a una orden de servicio, guarde primero los cambios', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }
        },
        cancelBussinessOrderItem() {
            this.isLoading = true;
            this.axios.post(businessOrderItemCancelUrl(), {
                'bussinesOrderItems': this.businessOrderItemList,
                'message':this.cancelMessage
            }).then(async response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    this.serviceOrder = ServiceOrder.create();

                    await Promise.all([this.getDataToAssign(true), this.getAvailability()]);

                    this.$nextTick(() => {
                        this.$bvModal.hide('cancel-modal');
                    });
                }
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });

                this.$nextTick(() => {
                    this.$bvModal.hide('cancel-modal');
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        updatePassengers(businessOrderItem) {
            let selectedPassengers = businessOrderItem.passengers.filter(passenger => passenger.cancel);

            businessOrderItem.cancelAdults = selectedPassengers.filter(passenger => passenger.type === 'ADULT').length;
            businessOrderItem.cancelChildren = selectedPassengers.filter(passenger => passenger.type === 'CHILD').length;
        },
        saveServiceOrder() {
            if (this.serviceOrder.passengerCapacity < (this.serviceOrder.adultsQty + this.serviceOrder.childrenQty)) {
                this.$bvToast.toast('La cantidad de pasajeros excede la disponibilidad del vehiculo.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });

                return;
            }

            let save = () => {
                // if (this.serviceOrder.serviceOrderItems.length > 0 || !this.serviceOrder.id) {
                this.isLoading = true;
                this.axios.post(serviceOrderUrl(), this.serviceOrder).then(async response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: this.swalTitle,
                            variant: 'success'
                        });

                        this.serviceOrder = ServiceOrder.create();

                        await Promise.all([this.getDataToAssign(true), this.getAvailability()]);
                        // this.getServiceOrdersUnassigned();
                    } else {
                        this.$bvToast.toast(response.data.message, {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }
                }).catch(() => {
                    this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
            };

            let overlappedSchedules = [];
            let serviceOrders = this.serviceOrders.filter(order => order.id !== this.serviceOrder.id && order.operatorId &&
                order.operatorId === this.serviceOrder.operatorId);
            let start = this.serviceOrder.startDate + ' ' +
                (this.serviceOrder.startTime !== '' ? this.serviceOrder.startTime : this.serviceOrder.serviceStartTime);
            let end = this.serviceOrder.endDate + ' ' +
                (this.serviceOrder.endTime !== '' ? this.serviceOrder.endTime : this.serviceOrder.serviceEndTime);
            let serviceStart = dayjs(start);
            let serviceEnd = dayjs(end);

            for (let order of serviceOrders) {
                let orderStart = order.startDate + ' ' + (order.startTime !== '' ? order.startTime : order.serviceStartTime);
                let orderEnd = order.endDate + ' ' + (order.endTime !== '' ? order.endTime : order.serviceEndTime);

                let scheduleStart = dayjs(orderStart), scheduleEnd = dayjs(orderEnd);
                let sameDay = scheduleStart.format('YYYY-MM-DD') === scheduleEnd.format('YYYY-MM-DD');

                if ((serviceStart.isBetween(scheduleStart, scheduleEnd, 'minute', '[]') || serviceEnd.isBetween(scheduleStart, scheduleEnd, 'minute', '[]') ||
                    scheduleStart.isBetween(serviceStart, serviceEnd, 'minute', '[]') || scheduleEnd.isBetween(serviceStart, serviceEnd, 'minute', '[]'))) {
                    overlappedSchedules.push({
                        id: order.id,
                        folio: order.folio,
                        start: scheduleStart.format('YYYY-MM-DD HH:mm'),
                        end: sameDay ? scheduleEnd.format('HH:mm') : scheduleEnd.format('YYYY-MM-DD HH:mm')
                    });
                }
            }

            if (overlappedSchedules.length > 0) {
                let text = `<table class="table table-bordered">
                            <thead>
                            <tr><th>Orden de servicio</th><th>Horario</th></tr>
                            </thead>
                            <tbody>
                            ${overlappedSchedules.map(schedule => '<tr style="white-space: nowrap;"><td>' + schedule.folio +
                                '</td><td>' + schedule.start + ' - ' + schedule.end + '</td></tr>').join('')}
                            </tbody>
                            </table>`;

                this.$swal({
                    title: 'Este operador ya está asignado a los siguientes servicios en el mismo horario. ¿Deseas continuar?',
                    html: text,
                    icon: 'warning'
                }).then(result => {
                    if (result.value) {
                        save();
                    }
                });
            } else {
                save();
            }
        },
        removeServiceOrder() {
            let title = '¿Estas seguro de eliminar la orden de servicio?';

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;

                    if (this.serviceOrder.id > 0) {
                        this.removeServiceOrderItem(true);

                        this.axios.post(serviceOrderRemoveUrl(), this.serviceOrder).then(async response => {
                            if (response.data.code === constants.CODE_OK) {
                                this.$root.$bvToast.toast(response.data.message, {
                                    title: this.swalTitle,
                                    variant: 'success'
                                });

                                this.serviceOrder = ServiceOrder.create();
                                await Promise.all([this.getDataToAssign(true), this.getAvailability()]);
                            } else {
                                this.$bvToast.toast(response.data.message, {
                                    title: this.swalTitle,
                                    variant: 'danger'
                                });
                            }
                        }).catch(() => {
                            this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }).finally(() => {
                            this.isLoading = false;
                        });
                    } else {
                        this.$bvToast.toast('La orden de servicio ha sido eliminada exitosamente.', {
                            title: this.swalTitle,
                            variant: 'success'
                        });

                        this.serviceOrder = ServiceOrder.create();

                        Promise.all([this.getDataToAssign(true), this.getAvailability()]).finally(() => {
                            this.isLoading = false;
                        });
                    }
                }
            });
        },
        cancelServiceOrder() {
            let title = '¿Estas seguro de cancelar la orden de servicio y los pasajeros que la componen?';

            this.$swal({
                title: title,
                icon: 'question',
                text: 'Razón de la cancelación: ',
                input: 'text',
                inputValidator: value => {
                    if (!value) {
                        return 'Es necesario ingresar una razón';
                    }
                }
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.serviceOrder.cancelNotes = result.value;

                    this.axios.post(serviceOrderCancelUrl(), this.serviceOrder).then(async response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.serviceOrder = ServiceOrder.create();

                            await Promise.all([this.getDataToAssign(true), this.getAvailability()]);
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        statusServiceOrder() {
            let title = '';
            if (this.serviceOrder.status == 'DRAFT') {
                title = '¿Procesar la orden de servicio?';
            } else {
                title = '¿Terminar la orden de servicio?';
            }

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.axios.post(serviceOrderStatusUrl(), this.serviceOrder).then(async response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.serviceOrder = ServiceOrder.create();
                            await Promise.all([this.getDataToAssign(true), this.getAvailability()]);
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });

                }
            });
        },
        statusLockChange() {
            let title = '';
            if (this.serviceOrder.statusLock == 1) {
                title = '¿Desbloquear la orden de servicio?';
            } else {
                title = '¿Bloquear la orden de servicio?';
            }

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.axios.post(serviceOrderLockUrl(), this.serviceOrder).then(async response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.serviceOrder = ServiceOrder.create();
                            await Promise.all([this.getDataToAssign(true), this.getAvailability()]);
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        saveVehicle() {
            this.isLoading = true;

            this.axios.post(vehicleUrl(), this.vehicle).then(async response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    this.vehicle.id = 0;
                    this.vehicle.brand = '';
                    this.vehicle.model = '';
                    this.vehicle.year = '';
                    this.vehicle.licensePlate = '';
                    this.vehicle.passengerCapacity = '';
                    this.vehicle.type = '';
                    this.vehicle.businessEntity = '';
                    this.vehicle.paymentType = '';
                    this.vehicle.amount1 = 0;
                    this.vehicle.amount2 = 0;

                    await Promise.all([this.getDataToAssign(true), this.getAvailability()]);

                    this.$nextTick(() => {
                        this.$bvModal.hide('vehicle-modal');
                    });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        cancelVehicle() {
            this.$bvModal.hide('vehicle-modal');

            this.vehicle.id = 0;
            this.vehicle.brand = '';
            this.vehicle.model = '';
            this.vehicle.year = '';
            this.vehicle.licensePlate = '';
            this.vehicle.passengerCapacity = '';
            this.vehicle.type = '';
            this.vehicle.businessEntity = '';
            this.vehicle.paymentType = '';
            this.vehicle.amount1 = 0;
            this.vehicle.amount2 = 0;
        },
        disabledVehicle() {
            this.$swal({
                title: `¿Desactivar el vehículo ${this.activeVehicle.brand} ${this.activeVehicle.model} [${this.activeVehicle.licensePlate}]?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;

                    this.axios.put(vehicleEnableUrl(), {
                        vehicleId: this.activeVehicle.id,
                        enable: false
                    }).then(async response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            await Promise.all([this.getDataToAssign(true), this.getAvailability()]);
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al actualizar el estatus.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        externalService() {
            this.vehicle.id = 0;
            this.vehicle.brand = 'Agencia';
            this.vehicle.model = 'Externa';
            this.vehicle.year = 0;
            this.vehicle.licensePlate = this.vehicle.businessEntity.legalName;
            this.vehicle.passengerCapacity = '100';
            this.vehicle.type = 'EXTERNAL_SERVICE';

            this.saveVehicle();

            this.$nextTick(() => {
                this.$bvModal.hide('external-service-modal');
            });
        },
        cancelExternalService() {
            this.$bvModal.hide('external-service-modal');

            this.vehicle.id = 0;
            this.vehicle.brand = '';
            this.vehicle.model = '';
            this.vehicle.year = '';
            this.vehicle.licensePlate = '';
            this.vehicle.passengerCapacity = '';
            this.vehicle.type = '';
            this.vehicle.businessEntity = '';
            this.vehicle.paymentType = '';
            this.vehicle.amount1 = 0;
            this.vehicle.amount2 = 0;
        },
        isValidServiceData(data) {
            let isValid = true;
            let serviceId = parseInt(this.serviceOrder.serviceId);

            for (let item of data) {
                if (serviceId === 0) {
                    serviceId = parseInt(item.serviceId);
                }

                if (serviceId > 0 && serviceId !== parseInt(item.serviceId)) {
                    isValid = false;
                    break;
                }
            }

            return isValid;
        },
        isValidServiceDate(data) {
            let isValidDate = true;
            let serviceDate = this.serviceOrder.startDate;

            for (let item of data) {
                if (!serviceDate) {
                    serviceDate = item.startDate;
                }

                if (serviceDate !== item.startDate) {
                    isValidDate = false;
                    break;
                }
            }

            return isValidDate;
        },
        isValidServiceType(data) {
            let isValidType = true;
            let serviceType = this.serviceOrder.serviceType;
            let businessOrderId = '';

            if (this.serviceOrder.serviceOrderItems.length > 0) {
                businessOrderId = this.serviceOrder.serviceOrderItems[0].businessOrderItem.businessOrderItemId;
            }

            for (let item of data) {
                if (!serviceType) {
                    serviceType = item.type;
                }

                if (!businessOrderId) {
                    businessOrderId = item.businessOrderItemId;
                }

                if (serviceType != item.type || (item.type == 'EXCLUSIVE' && businessOrderId !== item.businessOrderItemId)) {
                    isValidType = false;
                    break;
                }
            }

            return isValidType;
        },
        setFilterBusinessEntity(value) {
            this.filters.businessEntity = JSON.parse(JSON.stringify(value));
            this.getDataToAssign(true);
        },
        setFilterHotel(value) {
            this.filters.hotel = JSON.parse(JSON.stringify(value));
            this.getDataToAssign(true);
        },
        setFilterFlightSchedule(value) {
            this.filters.flightSchedule = JSON.parse(JSON.stringify(value));
            this.getDataToAssign(true);
        },
        setFilterFlightNumber(value) {
            this.filters.flightNumber = JSON.parse(JSON.stringify(value));
            this.getDataToAssign(true);

        },
        setFilterService(value) {
            this.filters.service = JSON.parse(JSON.stringify(value));
            this.getDataToAssign(true);
        },
        openScheduleModal(serviceOrder, availableVehicle) {
            this.isLoading = true;

            this.scheduleServiceOrder = ServiceOrder.create();

            this.scheduleServiceOrder.id = serviceOrder.id;
            this.scheduleServiceOrder.vehicleId = availableVehicle.id;
            this.scheduleServiceOrder.vehicleYear = availableVehicle.year;
            this.scheduleServiceOrder.vehicleBrand = availableVehicle.brand;
            this.scheduleServiceOrder.vehicleModel = availableVehicle.model;
            this.scheduleServiceOrder.vehicleLicensePlate = availableVehicle.licensePlate;
            this.scheduleServiceOrder.passengerCapacity = availableVehicle.passengerCapacity;
            this.scheduleServiceOrder.availabilityPassengers = availableVehicle.passengerCapacity;
            this.scheduleServiceOrder.serviceId = serviceOrder.serviceId;
            this.scheduleServiceOrder.serviceName = serviceOrder.serviceName;
            this.scheduleServiceOrder.serviceDescription = serviceOrder.serviceDescription;
            this.scheduleServiceOrder.serviceType = serviceOrder.serviceType;
            this.scheduleServiceOrder.adultsQty = serviceOrder.adultsQty;
            this.scheduleServiceOrder.childrenQty = serviceOrder.childrenQty;
            this.scheduleServiceOrder.operatorId = serviceOrder.operatorId;
            this.scheduleServiceOrder.serviceDurationType = serviceOrder.serviceDurationType;
            this.scheduleServiceOrder.serviceStartTime = serviceOrder.serviceStartTime;
            this.scheduleServiceOrder.serviceEndTime = serviceOrder.serviceEndTime;
            this.scheduleServiceOrder.startDate = serviceOrder.startDate;
            this.scheduleServiceOrder.endDate = serviceOrder.endDate;
            this.scheduleServiceOrder.startTime = serviceOrder.startTime;
            this.scheduleServiceOrder.endTime = serviceOrder.endTime;
            this.scheduleServiceOrder.folio = serviceOrder.folio;
            this.scheduleServiceOrder.status = serviceOrder.status;
            this.scheduleServiceOrder.statusLock = serviceOrder.statusLock;
            this.scheduleServiceOrder.supplierId = serviceOrder.supplierId;

            Promise.all([this.getDataToServiceOrder(this.scheduleServiceOrder)]).finally(() => {
                this.isLoading = false;
                this.$bvModal.show('schedule-modal');
            });
        },
        saveSchedule() {
            this.isLoading = true;

            this.axios.post(serviceOrderScheduleUrl(), this.scheduleServiceOrder).then(async response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    this.scheduleServiceOrder = ServiceOrder.create();

                    this.$nextTick(() => {
                        this.$bvModal.hide('schedule-modal');
                    });

                    await Promise.all([this.getDataToAssign(true), this.getAvailability()]);
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                    this.$nextTick(() => {
                        this.$bvModal.hide('schedule-modal');
                    });
                }
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al guardar el horario.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        getExpenseData() {
            return this.axios.get(serviceOrderExpenseDataUrl(this.serviceOrder.id, this.serviceOrder.serviceId)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    // this.serviceOrder.expenseList = Expense.create();
                    this.serviceOrder.expenseList = [];

                    response.data.data.forEach(expenseData => {
                        let expenseListItem = Expense.create();

                        expenseListItem.id = expenseData.id;
                        expenseListItem.objectId = expenseData.objectId;
                        expenseListItem.supplierId = expenseData.supplierId;
                        expenseListItem.name = expenseData.name;
                        expenseListItem.category = expenseData.category;
                        expenseListItem.paymentType = expenseData.paymentType;
                        expenseListItem.unitPrice = expenseData.unitPrice;
                        expenseListItem.qty = expenseData.qty;
                        expenseListItem.total = expenseData.total;
                        expenseListItem.notes = expenseData.notes;
                        expenseListItem.createdBy = expenseData.createdBy;
                        expenseListItem.createdDate = expenseData.createdDate;
                        expenseListItem.isCancelled = expenseData.isCancelled;
                        expenseListItem.isExtra = expenseData.isExtra;

                        this.serviceOrder.expenseList.push(expenseListItem);

                    });

                    if (!this.serviceOrder.id) {
                        if (this.activeVehicle.type === 'RENT') {
                            let index = this.serviceOrder.expenseList.findIndex(expenseItem => expenseItem.category === 'VEHICLE');
                            this.serviceOrder.expenseList[index].paymentType = this.activeVehicle.paymentType;
                            this.serviceOrder.expenseList[index].unitPrice = this.activeVehicle.amount1;
                            this.serviceOrder.expenseList[index].supplierId = this.activeVehicle.businessEntityId;
                        } else {
                            let index = this.serviceOrder.expenseList.findIndex(expenseItem => expenseItem.category === 'VEHICLE');
                            this.serviceOrder.expenseList.splice(index, 1);
                        }

                        if (this.activeVehicle.type === 'EXTERNAL_SERVICE') {
                            let expenseList = [];

                            let expense1 = Expense.create();

                            expense1.paymentType = this.activeVehicle.paymentType;
                            expense1.unitPrice = this.activeVehicle.amount1;
                            expense1.category = 'EXTERNAL_SERVICE';
                            expense1.name = 'Tarifa Adulto';
                            expense1.supplierId = this.activeVehicle.businessEntityId;
                            expense1.isExtra = false;

                            expenseList.push(expense1);

                            let expense2 = Expense.create();

                            expense2.paymentType = this.activeVehicle.paymentType;
                            expense2.unitPrice = this.activeVehicle.amount2;
                            expense2.category = 'EXTERNAL_SERVICE';
                            expense2.name = 'Tarifa Niño';
                            expense2.supplierId = this.activeVehicle.businessEntityId;
                            expense2.isExtra = false;

                            expenseList.push(expense2);

                            this.serviceOrder.expenseList = expenseList;
                        }
                    }

                    this.updateExpensesQty();
                    this.calculateExpenseTotal();
                }
            });
        },
        saveExpenses() {
            this.isLoading = true;

            this.axios.post(serviceOrderExpenseUrl(), this.serviceOrder).then(async response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        variant: 'success'
                    });

                    await this.getExpenseData();
                    this.$nextTick(() => {
                        this.$bvModal.hide('expense-modal');
                    });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        addExpense() {
            this.serviceOrder.expenseList.push(Expense.create());

        },
        deleteExpense(expense) {
            if (expense.id > 0) {
                expense.isCancelled = true;
            } else {
                let index = this.serviceOrder.expenseList.findIndex(expenseItem => expenseItem.guid === expense.guid);

                if (index >= 0) {
                    this.serviceOrder.expenseList.splice(index, 1);
                }
            }
            this.calculateExpenseTotal();
        },
        acceptExpenses() {
            this.$nextTick(() => {
                this.$bvModal.hide('expense-modal');
            });
        },
        cancelExpenses() {
            this.serviceOrder.expenseList.splice(0);

            this.expenseListCache.forEach(item => {
                this.serviceOrder.expenseList.push(item);
            });

            this.calculateExpenseTotal();

            this.$nextTick(() => {
                this.$bvModal.hide('expense-modal');
            });
        },
        updateExpensesQty() {
            this.serviceOrder.expenseList.forEach(expense => {
                if (expense.name === 'Entrada Adulto' || expense.name === 'Tarifa Adulto') {
                    expense.qty = this.serviceOrder.adultsQty;
                }

                if (expense.name === 'Entrada Niño' || expense.name === 'Tarifa Niño') {
                    expense.qty = this.serviceOrder.childrenQty;
                }

                if (expense.name === 'Desayunos') {
                    expense.qty = this.serviceOrder.adultsQty + this.serviceOrder.childrenQty;
                }
            });
        },
        calculateExpenseTotal() {
            this.expenseTotal = 0;

            this.serviceOrder.expenseList.forEach(expense => {
                if (!expense.isCancelled) {
                    expense.total = expense.unitPrice * expense.qty;
                    this.expenseTotal = parseFloat(expense.total) + this.expenseTotal;
                }
            });
        },
        expensesPayment() {
            let title = 'Deseas generar los pagos de los gastos actuales';

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.axios.post(paymentExpenseUrl(), this.serviceOrder).then(async response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.serviceOrder = ServiceOrder.create();

                            await Promise.all([this.getDataToAssign(true), this.getAvailability()]);
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        servicePage() {
            this.isLoading = true;
            this.axios.post(servicePageUrl(), this.serviceOrder).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = this.serviceOrder.folio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar la hoja de servicio.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        operatorSchedulesOverlap(serviceOrder) {
            let serviceOrders = this.serviceOrders.filter(order => order.id !== serviceOrder.id && order.operatorId === serviceOrder.operatorId);
            let start = serviceOrder.startDate + ' ' + (serviceOrder.startTime !== '' ? serviceOrder.startTime : serviceOrder.serviceStartTime);
            let end = serviceOrder.endDate + ' ' + (serviceOrder.endTime !== '' ? serviceOrder.endTime : serviceOrder.serviceEndTime);
            let serviceStart = dayjs(start);
            let serviceEnd = dayjs(end);

            for (let order of serviceOrders) {
                let orderStart = order.startDate + ' ' + (order.startTime !== '' ? order.startTime : order.serviceStartTime);
                let orderEnd = order.endDate + ' ' + (order.endTime !== '' ? order.endTime : order.serviceEndTime);

                let scheduleStart = dayjs(orderStart), scheduleEnd = dayjs(orderEnd);

                if ((serviceStart.isBetween(scheduleStart, scheduleEnd, 'minute', '[]') || serviceEnd.isBetween(scheduleStart, scheduleEnd, 'minute', '[]') ||
                        scheduleStart.isBetween(serviceStart, serviceEnd, 'minute', '[]') || scheduleEnd.isBetween(serviceStart, serviceEnd, 'minute', '[]'))) {
                    return true;
                }
            }

            return false;
        }
    },
    watch: {
        'filters.date'() {
            this.isLoading = true;

            this.filters.businessEntity = null;
            this.filters.hotel = null;
            this.filters.flightSchedule = '';
            this.filters.flightNumber = '';
            this.filters.service = null;
            this.serviceOrder = ServiceOrder.create();

            Promise.all([this.getDataToAssign(true), this.getAvailability()]).finally(() => {
                this.isLoading = false;
            });
        }
        //,
        // 'filters.businessEntity'() {
        //     this.getDataToAssign(false);
        // },
        // 'filters.hotel'() {
        //     this.getDataToAssign(false);
        // },
        // 'filters.flightSchedule'() {
        //     this.getDataToAssign(false);
        // },
        // 'filters.flightNumber'() {
        //     this.getDataToAssign(false);
        // },
        // 'filters.service'() {
        //     this.getDataToAssign(false);
        // }
    },
    computed: {
        adultsToAssign() {
            return this.dataToAssign.map(data => data.availableAdults).reduce((previous, current) => previous + current, 0);
        },
        childrenToAssign() {
            return this.dataToAssign.map(data => data.availableChildren).reduce((previous, current) => previous + current, 0);
        },
        selectedAdultsToAssign() {
            return this.dataToAssign.filter(data => data.isActive).map(data => data.availableAdults).reduce((previous, current) => previous + current, 0);
        },
        selectedChildrenToAssign() {
            return this.dataToAssign.filter(data => data.isActive).map(data => data.availableChildren).reduce((previous, current) => previous + current, 0);
        },
        selectedDataToAssign() {
            return this.dataToAssign.filter(data => data.isActive);
        },
        activeVehicle() {
            return this.availableVehicles.find(data => data.isActive);
        },
        activeServiceOrder() {
            return this.serviceOrders.find(data => data.isActive);
        },
        selectedServiceOrderItems() {
            return this.serviceOrder.serviceOrderItems.filter(data => data.isActive);
        }
    }
};
</script>

<style scoped>
    .table-customer-height {
        max-height: 60vh !important;
        overflow-y: auto !important;
    }

    .table-customer-height-v2 {
        max-height: 40vh !important;
        overflow-y: auto !important;
    }

    .vs__dropdown-menu {
        z-index: 10010 !important;
    }

    .paymentType {
        z-index: unset;
        position: relative;
    }
</style>