import { v4 as uuidv4 } from 'uuid';

export class Expense {
    constructor(id, objectId, supplierId, name, category, paymentType, unitPrice, qty, total, notes, createdBy, createdDate,
        updatedBy, updatedDate, status, isExtra = false, isCancelled = false) {
        this.id = id;
        this.objectId = objectId;
        this.supplierId = supplierId;
        this.name = name;
        this.category = category;
        this.paymentType = paymentType;
        this.unitPrice = unitPrice;
        this.qty = qty;
        this.total = total;
        this.notes = notes;
        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.status = status;
        this.isExtra = isExtra;
        this.isCancelled = isCancelled;
        this.updatedBy = updatedBy;
        this.updatedDate = updatedDate;

        this.guid = uuidv4();
    }
    static create() {
        return new Expense(0, 0, 1, '', '', 'CASH', '', 1, 0, '', '', '', '', '', '', true, false);
    }

    static createFromObject(expense) {
        return new Expense(expense.id, expense.objectId, expense.supplierId, expense.name, expense.category, expense.paymentType,
            expense.unitPrice, expense.qty, expense.total, expense.notes, expense.createdBy, expense.createdDate, expense.updatedBy,
            expense.updatedDate, expense.status, expense.isExtra, expense.isCancelled);
    }
}
