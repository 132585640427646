import { v4 as uuidv4 } from 'uuid';

export class ServiceOrder {
    constructor(id, folio, adultsQty, childrenQty, vehicleId, vehicleYear, vehicleBrand, vehicleModel, vehicleLicensePlate,
        passengerCapacity, operatorId, operatorName, notes, status, availabilityPassengers, serviceOrderItems, serviceId,
        serviceName, serviceDescription, serviceType, serviceDurationType, serviceStartTime, serviceEndTime, startDate,
        endDate, startTime, endTime, statusLock, supplierId, expenseList) {
        this.id = id;
        this.folio = folio;
        this.adultsQty = adultsQty;
        this.childrenQty = childrenQty;
        this.vehicleId = vehicleId;
        this.vehicleYear = vehicleYear;
        this.vehicleBrand = vehicleBrand;
        this.vehicleModel = vehicleModel;
        this.vehicleLicensePlate = vehicleLicensePlate;
        this.passengerCapacity = passengerCapacity;
        this.operatorId = operatorId;
        this.operatorName = operatorName;
        this.notes = notes;
        this.status = status;
        this.availabilityPassengers = availabilityPassengers;
        this.serviceOrderItems = serviceOrderItems;
        this.serviceId = serviceId;
        this.serviceName = serviceName;
        this.serviceDescription = serviceDescription;
        this.serviceType = serviceType;
        this.serviceDurationType = serviceDurationType;
        this.serviceStartTime = serviceStartTime;
        this.serviceEndTime = serviceEndTime;
        this.startDate = startDate;
        this.endDate = endDate;
        this.startTime = startTime;
        this.endTime = endTime;
        this.statusLock = statusLock;
        this.supplierId = supplierId;
        this.expenseList = expenseList;

        this.guid = uuidv4();
        this.operatorSchedulesOverlap = false;
    }

    static create() {
        return new ServiceOrder(0, '', 0, 0, 0, '', '', '',
            '', 0, null, '', '', 'DRAFT', 0,
            [], 0, '', '', '', '', '', '', '',
            '', '', '', 0, 0, []);
    }

    clone() {
        return new ServiceOrder(this.id, this.folio, this.adultsQty, this.childrenQty, this.vehicleId, this.vehicleYear,
            this.vehicleBrand, this.vehicleModel, this.vehicleLicensePlate, this.passengerCapacity, this.operatorId,
            this.operatorName, this.notes, this.status, this.availabilityPassengers, this.serviceOrderItems,
            this.serviceId, this.serviceName, this.serviceDescription, this.serviceType, this.serviceDurationType,
            this.serviceStartTime, this.serviceEndTime, this.startDate, this.endDate, this.startTime, this.endTime,
            this.statusLock, this.supplierId, this.expenseList);
    }
}