import { v4 as uuidv4 } from 'uuid';

export class ServiceOrderItem {
    constructor(id, businessOrderItem, adults, children, passengerData, isActive) {
        this.id = id;
        this.businessOrderItem = businessOrderItem;
        this.adults = adults;
        this.children = children;
        this.passengerData = passengerData;
        this.isActive = isActive;

        this.guid = uuidv4();
    }

    static create() {
        return new ServiceOrderItem(0, null, 0, 0, null, false);
    }

    clone() {
        return new ServiceOrderItem(this.id, this.businessOrderItem, this.adults, this.children, this.passengerData, this.isActive);
    }
}